import {relation, text, field} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Role extends Base {
  static table = 'roles';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
  };

  @relation('programs', 'program_id') program;

  @text('name') name;
  @field('managed') managed;
  @field('organization_create') organizationCreate;
  @field('organization_edit') organizationEdit;
  @field('organization_delete') organizationDelete;
  @field('organization_view') organizationView;
  @field('organization_assigned_only') organizationAssignedOnly;
  @field('prompt_create') promptCreate;
  @field('prompt_edit') promptEdit;
  @field('prompt_delete') promptDelete;
  @field('prompt_view') promptView;
  @field('prompt_assigned_only') promptAssignedOnly;
  @field('tag_create') tagCreate;
  @field('tag_edit') tagEdit;
  @field('tag_delete') tagDelete;
  @field('tag_view') tagView;
  @field('tag_assigned_only') tagAssignedOnly;
  @field('role_create') roleCreate;
  @field('role_edit') roleEdit;
  @field('role_delete') roleDelete;
  @field('role_view') roleView;
  @field('role_assigned_only') roleAssignedOnly;
  @field('organization_diagnosis_create') organizationDiagnosisCreate;
  @field('organization_diagnosis_edit') organizationDiagnosisEdit;
  @field('organization_diagnosis_delete') organizationDiagnosisDelete;
  @field('organization_diagnosis_view') organizationDiagnosisView;
  @field('organization_diagnosis_assigned_only')
  organizationDiagnosisAssignedOnly;
  @field('appointment_create') appointmentCreate;
  @field('appointment_edit') appointmentEdit;
  @field('appointment_delete') appointmentDelete;
  @field('appointment_view') appointmentView;
  @field('appointment_assigned_only') appointmentAssignedOnly;
  @field('session_create') sessionCreate;
  @field('session_edit') sessionEdit;
  @field('session_delete') sessionDelete;
  @field('session_view') sessionView;
  @field('session_assigned_only') sessionAssignedOnly;
  @field('patient_create') patientCreate;
  @field('patient_edit') patientEdit;
  @field('patient_delete') patientDelete;
  @field('patient_view') patientView;
  @field('patient_assigned_only') patientAssignedOnly;
  @field('treatment_plan_create') treatmentPlanCreate;
  @field('treatment_plan_edit') treatmentPlanEdit;
  @field('treatment_plan_delete') treatmentPlanDelete;
  @field('treatment_plan_view') treatmentPlanView;
  @field('treatment_plan_assigned_only') treatmentPlanAssignedOnly;
  @field('note_template_create') noteTemplateCreate;
  @field('note_template_edit') noteTemplateEdit;
  @field('note_template_delete') noteTemplateDelete;
  @field('note_template_view') noteTemplateView;
  @field('note_template_assigned_only') noteTemplateAssignedOnly;
  @field('authorization_create') authorizationCreate;
  @field('authorization_edit') authorizationEdit;
  @field('authorization_delete') authorizationDelete;
  @field('authorization_view') authorizationView;
  @field('authorization_assigned_only') authorizationAssignedOnly;
  @field('environmental_factor_create') environmentalFactorCreate;
  @field('environmental_factor_edit') environmentalFactorEdit;
  @field('environmental_factor_delete') environmentalFactorDelete;
  @field('environmental_factor_view') environmentalFactorView;
  @field('environmental_factor_assigned_only') environmentalFactorAssignedOnly;
  @field('client_document_create') clientDocumentCreate;
  @field('client_document_edit') clientDocumentEdit;
  @field('client_document_delete') clientDocumentDelete;
  @field('client_document_view') clientDocumentView;
  @field('client_document_assigned_only') clientDocumentAssignedOnly;
  @field('client_history_create') clientHistoryCreate;
  @field('client_history_edit') clientHistoryEdit;
  @field('client_history_delete') clientHistoryDelete;
  @field('client_history_view') clientHistoryView;
  @field('client_history_assigned_only') clientHistoryAssignedOnly;
  @field('assigned_staff_create') assignedStaffCreate;
  @field('assigned_staff_edit') assignedStaffEdit;
  @field('assigned_staff_delete') assignedStaffDelete;
  @field('assigned_staff_view') assignedStaffView;
  @field('assigned_staff_assigned_only') assignedStaffAssignedOnly;
  @field('staff_document_create') staffDocumentCreate;
  @field('staff_document_edit') staffDocumentEdit;
  @field('staff_document_delete') staffDocumentDelete;
  @field('staff_document_view') staffDocumentView;
  @field('staff_document_assigned_only') staffDocumentAssignedOnly;
  @field('staff_history_create') staffHistoryCreate;
  @field('staff_history_edit') staffHistoryEdit;
  @field('staff_history_delete') staffHistoryDelete;
  @field('staff_history_view') staffHistoryView;
  @field('staff_history_assigned_only') staffHistoryAssignedOnly;
  @field('user_create') userCreate;
  @field('user_edit') userEdit;
  @field('user_delete') userDelete;
  @field('user_view') userView;
  @field('user_assigned_only') userAssignedOnly;
  @field('assigned_client_create') assignedClientCreate;
  @field('assigned_client_edit') assignedClientEdit;
  @field('assigned_client_delete') assignedClientDelete;
  @field('assigned_client_view') assignedClientView;
  @field('assigned_client_assigned_only') assignedClientAssignedOnly;
  @field('report_create') reportCreate;
  @field('report_edit') reportEdit;
  @field('report_delete') reportDelete;
  @field('report_view') reportView;
  @field('report_assigned_only') reportAssignedOnly;
  @field('program_create') programCreate;
  @field('program_edit') programEdit;
  @field('program_delete') programDelete;
  @field('program_view') programView;
  @field('program_assigned_only') programAssignedOnly;
  @field('timesheet_create') timesheetCreate;
  @field('timesheet_edit') timesheetEdit;
  @field('timesheet_delete') timesheetDelete;
  @field('timesheet_view') timesheetView;
  @field('timesheet_assigned_only') timesheetAssignedOnly;
  @field('organization_location_create') organizationLocationCreate;
  @field('organization_location_edit') organizationLocationEdit;
  @field('organization_location_delete') organizationLocationDelete;
  @field('organization_location_view') organizationLocationView;
  @field('organization_location_assigned_only')
  organizationLocationAssignedOnly;
  @field('billing_create') billingCreate;
  @field('billing_edit') billingEdit;
  @field('billing_delete') billingDelete;
  @field('billing_view') billingView;
  @field('billing_assigned_only') billingAssignedOnly;
  @field('billing_payer_create') billingPayerCreate;
  @field('billing_payer_edit') billingPayerEdit;
  @field('billing_payer_delete') billingPayerDelete;
  @field('billing_payer_view') billingPayerView;
  @field('billing_payer_assigned_only') billingPayerAssignedOnly;
  @field('billing_claim_create') billingClaimCreate;
  @field('billing_claim_edit') billingClaimEdit;
  @field('billing_claim_delete') billingClaimDelete;
  @field('billing_claim_view') billingClaimView;
  @field('billing_claim_assigned_only') billingClaimAssignedOnly;
  @field('audit_create') auditCreate;
  @field('audit_edit') auditEdit;
  @field('audit_delete') auditDelete;
  @field('audit_view') auditView;
  @field('audit_assigned_only') auditAssignedOnly;
  @field('audit_session_create') auditSessionCreate;
  @field('audit_session_edit') auditSessionEdit;
  @field('audit_session_delete') auditSessionDelete;
  @field('audit_session_view') auditSessionView;
  @field('audit_session_assigned_only') auditSessionAssignedOnly;

  @field('progress_report_create') progressReportCreate;
  @field('progress_report_edit') progressReportEdit;
  @field('progress_report_delete') progressReportDelete;
  @field('progress_report_view') progressReportView;
}
