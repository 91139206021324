import React, {useState} from 'react';
import {View, Text, ScrollView, FlatList} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Typography} from 'src/styles';
import {DeleteModal, Modal} from 'src/design-system';
import OrganizationRolesForm from 'src/modules/organization/components/role-form';
import * as Yup from 'yup';
import NoResults from 'src/common-components/noResults';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import withState from 'src/redux/wrapper';
import {Q} from '@nozbe/watermelondb';
import RoleItem from 'src/modules/organization/components/role-item';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import Spinner from 'react-native-loading-spinner-overlay';
import {Role} from 'src/models';
import {useStyle} from 'src/providers/style';
import {useTranslations} from 'src/providers/translation';

interface Props {
  roles: any[];
  role: any;
}

const RolesAndPermissions = ({roles, role}: Props) => {
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState<any | boolean>(false);
  const [showDelete, setShowDelete] = useState<string | boolean>(false);

  const supportedPermissions = {
    organization: {
      key: 'organization',
      title: 'organization',
      permissions: ['Edit', 'Delete', 'View'],
    },
    prompt: {
      nested: true,
      key: 'prompt',
      title: 'prompt',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    tag: {
      nested: true,
      key: 'tag',
      title: 'tag',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    role: {
      nested: true,
      key: 'role',
      title: 'role',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    timesheet: {
      nested: true,
      key: 'timesheet',
      title: 'timesheet',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    noteTemplate: {
      nested: true,
      key: 'noteTemplate',
      title: 'noteTemplate',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    organizationDiagnosis: {
      nested: true,
      key: 'organizationDiagnosis',
      title: 'diagnoses',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    organizationLocation: {
      nested: true,
      key: 'organizationLocation',
      title: 'locations',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    appointment: {
      key: 'appointment',
      title: 'schedule',
      permissions: ['Create', 'Edit', 'Delete', 'View', 'Assigned Only'],
    },
    session: {
      key: 'session',
      title: 'session',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    client: {
      key: 'patient',
      title: 'client',
      permissions: ['Create', 'Edit', 'Delete', 'View', 'Assigned Only'],
    },
    treatmentPlan: {
      nested: true,
      key: 'treatmentPlan',
      title: 'treatmentPlan',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    clientHistory: {
      nested: true,
      key: 'clientHistory',
      title: 'clientHistory',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    authorization: {
      nested: true,
      key: 'authorization',
      title: 'authorization',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    progressReport: {
      nested: true,
      key: 'progressReport',
      title: 'progressReport',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    environmentalFactor: {
      nested: true,
      key: 'environmentalFactor',
      title: 'environmentalFactor',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    clientDocument: {
      nested: true,
      key: 'clientDocument',
      title: 'document',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    assignedStaff: {
      nested: true,
      key: 'assignedStaff',
      title: 'assignedStaff',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    staff: {
      key: 'user',
      title: 'staff',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    assignedClient: {
      nested: true,
      key: 'assignedClient',
      title: 'assignedClient',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    staffDocument: {
      nested: true,
      key: 'staffDocument',
      title: 'document',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    staffHistory: {
      nested: true,
      key: 'staffHistory',
      title: 'staffHistory',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    report: {
      key: 'report',
      title: 'report',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    program: {
      key: 'program',
      title: 'program',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    billing: {
      key: 'billing',
      title: 'billing',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    billingPayer: {
      nested: true,
      key: 'billingPayer',
      title: 'payer',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    billingClaim: {
      nested: true,
      key: 'billingClaim',
      title: 'claim',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    audit: {
      key: 'audit',
      title: 'audit',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
    auditSession: {
      nested: true,
      key: 'auditSession',
      title: 'session',
      permissions: ['Create', 'Edit', 'Delete', 'View'],
    },
  };

  const handleSubmit = async (payload: any) => {
    setLoading(true);
    if (edit) {
      if (!edit.managed) {
        await edit.updateEntity({
          name: payload.name,
          ...payload,
        });
      } else {
        await edit.updateEntity({
          name: payload.name,
        });
      }
    } else {
      database.write(async () => {
        await database.get(Role.table).create(entity => {
          entity.partition = selectedGroup;
          Object.assign(entity, payload);
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
    handleCancel();
    setLoading(false);
  };

  const handleCancel = () => {
    setSubmitting(!submitting);
    setEdit(false);
  };

  const deleteCallback = (item: any) => {
    setShowDelete(item);
  };
  const deleteCancelled = () => {
    setShowDelete(false);
  };
  const deleteConfirmed = async (item: any) => {
    item.delete();
    setShowDelete(false);
  };

  const initialValues = {
    name: edit ? edit.name : '',
    managed: edit ? edit.managed : false,
    organizationCreate: edit ? edit.organizationCreate : false,
    organizationEdit: edit ? edit.organizationEdit : false,
    organizationDelete: edit ? edit.organizationDelete : false,
    organizationView: edit ? edit.organizationView : false,
    organizationAssignedOnly: edit ? edit.organizationAssignedOnly : false,
    organizationDiagnosisCreate: edit
      ? edit.organizationDiagnosisCreate
      : false,
    organizationDiagnosisEdit: edit ? edit.organizationDiagnosisEdit : false,
    organizationDiagnosisDelete: edit
      ? edit.organizationDiagnosisDelete
      : false,
    organizationDiagnosisView: edit ? edit.organizationDiagnosisView : false,
    organizationDiagnosisAssignedOnly: edit
      ? edit.organizationDiagnosisAssignedOnly
      : false,
    organizationLocationCreate: edit ? edit.organizationLocationCreate : false,
    organizationLocationEdit: edit ? edit.organizationLocationEdit : false,
    organizationLocationDelete: edit ? edit.organizationLocationDelete : false,
    organizationLocationView: edit ? edit.organizationLocationView : false,
    organizationLocationAssignedOnly: edit
      ? edit.organizationLocationAssignedOnly
      : false,
    promptCreate: edit ? edit.promptCreate : false,
    promptEdit: edit ? edit.promptEdit : false,
    promptDelete: edit ? edit.promptDelete : false,
    promptView: edit ? edit.promptView : false,
    promptAssignedOnly: edit ? edit.promptAssignedOnly : false,
    tagCreate: edit ? edit.tagCreate : false,
    tagEdit: edit ? edit.tagEdit : false,
    tagDelete: edit ? edit.tagDelete : false,
    tagView: edit ? edit.tagView : false,
    tagAssignedOnly: edit ? edit.tagAssignedOnly : false,
    roleCreate: edit ? edit.roleCreate : false,
    roleEdit: edit ? edit.roleEdit : false,
    roleDelete: edit ? edit.roleDelete : false,
    roleView: edit ? edit.roleView : false,
    roleAssignedOnly: edit ? edit.roleAssignedOnly : false,
    timesheetCreate: edit ? edit.timesheetCreate : false,
    timesheetEdit: edit ? edit.timesheetEdit : false,
    timesheetDelete: edit ? edit.timesheetDelete : false,
    timesheetView: edit ? edit.timesheetView : false,
    timesheetAssignedOnly: edit ? edit.timesheetAssignedOnly : false,
    noteTemplateCreate: edit ? edit.noteTemplateCreate : false,
    noteTemplateEdit: edit ? edit.noteTemplateEdit : false,
    noteTemplateDelete: edit ? edit.noteTemplateDelete : false,
    noteTemplateView: edit ? edit.noteTemplateView : false,
    noteTemplateAssignedOnly: edit ? edit.noteTemplateAssignedOnly : false,
    appointmentCreate: edit ? edit.appointmentCreate : false,
    appointmentEdit: edit ? edit.appointmentEdit : false,
    appointmentDelete: edit ? edit.appointmentDelete : false,
    appointmentView: edit ? edit.appointmentView : false,
    appointmentAssignedOnly: edit ? edit.appointmentAssignedOnly : false,
    sessionCreate: edit ? edit.sessionCreate : false,
    sessionEdit: edit ? edit.sessionEdit : false,
    sessionDelete: edit ? edit.sessionDelete : false,
    sessionView: edit ? edit.sessionView : false,
    sessionAssignedOnly: edit ? edit.sessionAssignedOnly : false,
    patientCreate: edit ? edit.patientCreate : false,
    patientEdit: edit ? edit.patientEdit : false,
    patientDelete: edit ? edit.patientDelete : false,
    patientView: edit ? edit.patientView : false,
    patientAssignedOnly: edit ? edit.patientAssignedOnly : false,
    treatmentPlanCreate: edit ? edit.treatmentPlanCreate : false,
    treatmentPlanEdit: edit ? edit.treatmentPlanEdit : false,
    treatmentPlanDelete: edit ? edit.treatmentPlanDelete : false,
    treatmentPlanView: edit ? edit.treatmentPlanView : false,
    treatmentPlanAssignedOnly: edit ? edit.treatmentPlanAssignedOnly : false,
    authorizationCreate: edit ? edit.authorizationCreate : false,
    authorizationEdit: edit ? edit.authorizationEdit : false,
    authorizationDelete: edit ? edit.authorizationDelete : false,
    authorizationView: edit ? edit.authorizationView : false,
    authorizationAssignedOnly: edit ? edit.authorizationAssignedOnly : false,
    environmentalFactorCreate: edit ? edit.environmentalFactorCreate : false,
    environmentalFactorEdit: edit ? edit.environmentalFactorEdit : false,
    environmentalFactorDelete: edit ? edit.environmentalFactorDelete : false,
    environmentalFactorView: edit ? edit.environmentalFactorView : false,
    environmentalFactorAssignedOnly: edit
      ? edit.environmentalFactorAssignedOnly
      : false,
    clientDocumentCreate: edit ? edit.clientDocumentCreate : false,
    clientDocumentEdit: edit ? edit.clientDocumentEdit : false,
    clientDocumentDelete: edit ? edit.clientDocumentDelete : false,
    clientDocumentView: edit ? edit.clientDocumentView : false,
    clientDocumentAssignedOnly: edit ? edit.clientDocumentAssignedOnly : false,
    clientHistoryCreate: edit ? edit.clientHistoryCreate : false,
    clientHistoryEdit: edit ? edit.clientHistoryEdit : false,
    clientHistoryDelete: edit ? edit.clientHistoryDelete : false,
    clientHistoryView: edit ? edit.clientHistoryView : false,
    clientHistoryAssignedOnly: edit ? edit.clientHistoryAssignedOnly : false,
    assignedStaffCreate: edit ? edit.assignedStaffCreate : false,
    assignedStaffEdit: edit ? edit.assignedStaffEdit : false,
    assignedStaffDelete: edit ? edit.assignedStaffDelete : false,
    assignedStaffView: edit ? edit.assignedStaffView : false,
    assignedStaffAssignedOnly: edit ? edit.assignedStaffAssignedOnly : false,
    staffDocumentCreate: edit ? edit.staffDocumentCreate : false,
    staffDocumentEdit: edit ? edit.staffDocumentEdit : false,
    staffDocumentDelete: edit ? edit.staffDocumentDelete : false,
    staffDocumentView: edit ? edit.staffDocumentView : false,
    staffDocumentAssignedOnly: edit ? edit.staffDocumentAssignedOnly : false,
    staffHistoryCreate: edit ? edit.staffHistoryCreate : false,
    staffHistoryEdit: edit ? edit.staffHistoryEdit : false,
    staffHistoryDelete: edit ? edit.staffHistoryDelete : false,
    staffHistoryView: edit ? edit.staffHistoryView : false,
    staffHistoryAssignedOnly: edit ? edit.staffHistoryAssignedOnly : false,
    userCreate: edit ? edit.userCreate : false,
    userEdit: edit ? edit.userEdit : false,
    userDelete: edit ? edit.userDelete : false,
    userView: edit ? edit.userView : false,
    userAssignedOnly: edit ? edit.userAssignedOnly : false,
    assignedClientCreate: edit ? edit.assignedClientCreate : false,
    assignedClientEdit: edit ? edit.assignedClientEdit : false,
    assignedClientDelete: edit ? edit.assignedClientDelete : false,
    assignedClientView: edit ? edit.assignedClientView : false,
    assignedClientAssignedOnly: edit ? edit.assignedClientAssignedOnly : false,
    reportCreate: edit ? edit.reportCreate : false,
    reportEdit: edit ? edit.reportEdit : false,
    reportDelete: edit ? edit.reportDelete : false,
    reportView: edit ? edit.reportView : false,
    reportAssignedOnly: edit ? edit.reportAssignedOnly : false,
    programCreate: edit ? edit.programCreate : false,
    programEdit: edit ? edit.programEdit : false,
    programDelete: edit ? edit.programDelete : false,
    programView: edit ? edit.programView : false,
    programAssignedOnly: edit ? edit.programAssignedOnly : false,
    auditCreate: edit ? edit.auditCreate : false,
    auditEdit: edit ? edit.auditEdit : false,
    auditDelete: edit ? edit.auditDelete : false,
    auditView: edit ? edit.auditView : false,
    auditAssignedOnly: edit ? edit.auditSessionAssignedOnly : false,
    auditSessionCreate: edit ? edit.auditSessionCreate : false,
    auditSessionEdit: edit ? edit.auditSessionEdit : false,
    auditSessionDelete: edit ? edit.auditSessionDelete : false,
    auditSessionView: edit ? edit.auditSessionView : false,
    auditSessionAssignedOnly: edit ? edit.auditSessionAssignedOnly : false,
    billingCreate: edit ? edit.billingCreate : false,
    billingEdit: edit ? edit.billingEdit : false,
    billingDelete: edit ? edit.billingDelete : false,
    billingView: edit ? edit.billingView : false,
    billingAssignedOnly: edit ? edit.billingAssignedOnly : false,
    billingPayerCreate: edit ? edit.billingPayerCreate : false,
    billingPayerEdit: edit ? edit.billingPayerEdit : false,
    billingPayerDelete: edit ? edit.billingPayerDelete : false,
    billingPayerView: edit ? edit.billingPayerView : false,
    billingPayerAssignedOnly: edit ? edit.billingPayerAssignedOnly : false,
    billingClaimCreate: edit ? edit.billingClaimCreate : false,
    billingClaimEdit: edit ? edit.billingClaimEdit : false,
    billingClaimDelete: edit ? edit.billingClaimDelete : false,
    billingClaimView: edit ? edit.billingClaimView : false,
    billingClaimAssignedOnly: edit ? edit.billingClaimAssignedOnly : false,
    progressReportCreate: edit ? edit.progressReportCreate : false,
    progressReportEdit: edit ? edit.progressReportCreate : false,
    progressReportDelete: edit ? edit.progressReportCreate : false,
    progressReportView: edit ? edit.progressReportCreate : false,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required(translations('required_role')),
  });

  return (
    <>
      <Spinner visible={loading} />
      <Modal
        show={[submitting, setSubmitting]}
        title={edit ? translations('edit_role') : translations('new_role')}>
        <OrganizationRolesForm
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validationSchema}
          permissions={supportedPermissions}
        />
      </Modal>
      <DeleteModal
        model={'Role'}
        text={showDelete ? `${showDelete?.name}` : null}
        show={[showDelete, setShowDelete]}
        cancelled={() => deleteCancelled()}
        handleSubmit={() => deleteConfirmed(showDelete)}
      />
      <ScrollView
        style={[styles.flex, styles.backgroundColorWhite, styles.borderRadius]}
        contentContainerStyle={[styles.paddingVertical]}>
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.paddingLTop,
            styles.paddingHorizontal,
            styles.paddingBottom,
          ]}>
          <Text style={[Typography.H4, styles.paddingMVertical]}>
            {translations('roles_and_permissions')}
          </Text>
          {role?.roleCreate ? (
            <RHButton
              onPress={() => {
                setEdit(false);
                setSubmitting(true);
              }}
              mode={'contained'}
              secondary
              icon="plus">
              {translations('add_role')}
            </RHButton>
          ) : (
            <></>
          )}
        </View>
        {roles.length === 0 ? (
          <NoResults message={translations('no_role')} />
        ) : (
          <FlatList
            scrollEnabled={true}
            data={roles}
            keyExtractor={item => item.id}
            renderItem={({item}: any) => (
              <RoleItem
                item={item}
                canEdit={role?.roleEdit}
                editSelected={() => {
                  setEdit(item);
                  setSubmitting(true);
                }}
                canDelete={role?.roleDelete && !item.managed}
                deleteSelected={() => {
                  deleteCallback(item);
                }}
                permissions={supportedPermissions}
              />
            )}
          />
        )}
      </ScrollView>
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables([], ({database, authentication}: any) => ({
    roles: database
      .get('roles')
      .query(
        Q.where('deleted_at', null),
        Q.where('_partition', authentication.selectedGroup),
      ),
  })),
)(RolesAndPermissions);
