import {field, relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgressReportCaregiver extends Base {
  static table = 'progress_report_caregiver';
  static associations = {
    caregivers: {type: 'belongs_to', key: 'caregiver_id'},
    progress_reports: {type: 'belongs_to', key: 'progress_report_id'},
  };

  @relation('caregivers', 'caregiver_id') caregiver;
  @relation('progress_reports', 'progress_report_id') progressReport;

  @field('caregiver_id') caregiverId;
  @field('caregiver_include') caregiverInclude;
}
