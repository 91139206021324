import {Q} from '@nozbe/watermelondb';
import {
  relation,
  date,
  field,
  lazy,
  children,
} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgressReport extends Base {
  static table = 'progress_reports';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
    authorizations: {type: 'belongs_to', key: 'authorization_id'},
    progress_report_assessment: {
      type: 'has_many',
      foreignKey: 'progress_report_id',
    },
    progress_report_program: {
      type: 'has_many',
      foreignKey: 'progress_report_id',
    },
    signatures: {type: 'has_many', foreignKey: 'entity_id'},
  };

  @relation('patients', 'patient_id') patient;
  @relation('authorizations', 'authorization_id') authorization;

  @date('start_date') startDate;
  @date('end_date') endDate;
  @field('patient_id') patientId;
  @field('authorization_id') authorizationId;
  @field('type') type;

  @field('caregiver_guidance_info') caregiverGuidanceInfo;
  @field('caregiver_guidance_info_include') caregiverGuidanceInfoInclude;
  @field('background_info') backgroundInfo;
  @field('background_info_include') backgroundInfoInclude;
  @field('medical_info') medicalInfo;
  @field('medical_info_include') medicalInfoInclude;
  @field('behavior_intervention_plan') behaviorInterventionPlan;
  @field('behavior_intervention_plan_include') behaviorInterventionPlanInclude;
  @field('summary') summary;
  @field('summary_include') summaryInclude;
  @field('crisis_plan') crisisPlan;
  @field('crisis_plan_include') crisisPlanInclude;
  @field('transition_plan') transitionPlan;
  @field('transition_plan_include') transitionPlanInclude;
  @field('recommendations') recommendations;
  @field('recommendations_include') recommendationsInclude;
  @field('therapist_signature') therapistSignature;
  @field('therapist_signature_2') therapistSignature2;
  @field('parent_signature') parentSignature;
  @field('signature_include') signatureInclude;

  @date('submitted_at') submittedAt;

  @field('cpt97151') cpt97151;
  @field('cpt97152') cpt97152;
  @field('cpt97153') cpt97153;
  @field('cpt97154') cpt97154;
  @field('cpt97155') cpt97155;
  @field('cpt97156') cpt97156;
  @field('cpt97157') cpt97157;
  @field('cpt0373T') cpt0373T;
  @field('cpt0362T') cpt0362T;
  @field('cptT1024') cptT1024;
  @field('cpt92507') cpt92507;
  @field('cpt92508') cpt92508;
  @field('cpt92526') cpt92526;
  @field('cpt92609') cpt92609;
  @field('cpt92522') cpt92522;
  @field('cpt92523') cpt92523;
  @field('cpt92610') cpt92610;
  @field('cptH2019') cptH2019;
  @field('cptT2024') cptT2024;
  @field('cptH0032') cptH0032;
  @field('cpt90791') cpt90791;
  @field('cpt90837') cpt90837;
  @field('cptH0031') cptH0031;
  @field('cptH2012') cptH2012;
  @field('cptS5111') cptS5111;
  @field('cptH2014') cptH2014;
  @field('cptH0046') cptH0046;

  //   TODO: add lazy queries

  @lazy
  progressReportCaregivers = this.collections
    .get('progress_report_caregiver')
    .query(
      Q.where('deleted_at', null),
      Q.where('progress_report_id', this.id),
      Q.on('caregivers', Q.and(Q.where('deleted_at', null))),
    );

  @lazy
  assessments = this.collections
    .get('assessments')
    .query(
      Q.where('deleted_at', null),
      Q.on(
        'progress_report_assessment',
        Q.and(
          Q.where('progress_report_id', this.id),
          Q.where('deleted_at', null),
        ),
      ),
    );

  @lazy
  programs = this.collections
    .get('programs')
    .query(
      Q.where('deleted_at', null),
      Q.on(
        'progress_report_program',
        Q.and(
          Q.where('progress_report_id', this.id),
          Q.where('deleted_at', null),
        ),
      ),
    );

  @lazy
  authPrograms = this.collections
    .get('progress_report_program')
    .query(Q.where('deleted_at', null), Q.where('progress_report_id', this.id));

  @lazy
  behaviorPrograms = this.collections
    .get('programs')
    .query(
      Q.where('deleted_at', null),
      Q.where('type', 'behavior'),
      Q.on(
        'progress_report_program',
        Q.and(
          Q.where('progress_report_id', this.id),
          Q.where('deleted_at', null),
        ),
      ),
    );

  @lazy
  behaviorAuthPrograms = this.collections
    .get('progress_report_program')
    .query(
      Q.where('deleted_at', null),
      Q.where('progress_report_id', this.id),
      Q.on(
        'programs',
        Q.and(Q.where('type', 'behavior'), Q.where('deleted_at', null)),
      ),
    );

  @lazy
  skillPrograms = this.collections
    .get('programs')
    .query(
      Q.where('deleted_at', null),
      Q.where('type', 'skill'),
      Q.on(
        'progress_report_program',
        Q.and(
          Q.where('progress_report_id', this.id),
          Q.where('deleted_at', null),
        ),
      ),
    );

  @lazy
  skillAuthPrograms = this.collections
    .get('progress_report_program')
    .query(
      Q.where('deleted_at', null),
      Q.where('progress_report_id', this.id),
      Q.on(
        'programs',
        Q.and(Q.where('type', 'skill'), Q.where('deleted_at', null)),
      ),
    );

  @children('signatures') signatures;

  @lazy
  progressReportSignatures = this.collections
    .get('signatures')
    .query(
      Q.where('entity_id', this.id),
      Q.where('entity', 'progressReport'),
      Q.where('deleted_at', null),
      Q.sortBy('created_at', Q.asc),
    );
}
