import {date, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Assessment extends Base {
  static table = 'assessments';
  static associations = {
    patients: {type: 'belongs_to', foreignKey: 'patient_id'},
    // assessment_document: {type: 'has_many', foreignKey: 'assessment_id'},
    progress_report_assessment: {type: 'has_many', foreignKey: 'assessment_id'},
  };

  @relation('patients', 'patient_id') patient;

  @text('_partition') partition;
  @date('assessment_date') assessmentDate;
  @text('start_time') startTime;
  @text('end_time') endTime;
  @text('overview') overview;
  @text('assessor') assessor;

  // @lazy
  // documents = this.collections
  //   .get('documents')
  //   .query(
  //     Q.where('deleted_at', null),
  //     Q.on(
  //       'assessment_document',
  //       Q.and(Q.where('assessment_id', this.id), Q.where('deleted_at', null)),
  //     ),
  //   );
}
