import {ProgramMethodAvatar} from 'src/modules/programs/components';
import {Text} from 'react-native';
import {Colors, Typography} from 'src/styles';
import React from 'react';
import {useStyle} from 'src/providers/style';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';

const ProgramListItem = ({program, selected}: any) => {
  const styles = useStyle();

  return (
    <>
      <ProgramMethodAvatar type={program?.method} />
      <Text
        style={[
          Typography.CAPTION_MEDIUM,
          styles.textColorPrimary700,
          styles.marginMLeft,
          selected ? {color: Colors.SECONDARY_800} : {},
        ]}
        numberOfLines={1}>
        {program?.name}
      </Text>
    </>
  );
};

export default compose(
  withDatabase,
  withObservables([], ({database, programId}: any) => ({
    program: database.get('programs').findAndObserve(programId),
  })),
)(ProgramListItem);
