import React from 'react';
import {Colors, Typography} from 'src/styles';
import {Platform, StyleSheet, Text, View} from 'react-native';
import {BetaIcon} from 'src/navigation/components/drawer-content-item';
import {Separator} from 'src/common-components/atoms';

interface Props {
  navigator: any;
  route?: any;
  location: string;
  children: JSX.Element | JSX.Element[] | Element[];
}

const TabBar = ({navigator, children, location, route}: Props) => {
  const tabBarListeners = ({navigation, route: tabRoute}: any) => ({
    tabPress: () => {
      if (Platform.OS === 'web') {
        navigation.navigate(location, {
          ...(route ? route.params : {}),
          screen: tabRoute.name,
        });
      }
    },
  });

  return (
    <navigator.Navigator
      screenOptions={({route: tabRoute}) => ({
        lazy: true,
        swipeEnabled: Platform.OS !== 'web',
        tabBarScrollEnabled: true,
        tabBarActiveTintColor: Colors.BLUE_700,
        tabBarItemStyle: {
          width: 'auto',
          paddingHorizontal: 20,
          marginBottom: 8,
        },
        tabBarStyle: {
          paddingTop: 4,
          backgroundColor: '#00000000',
        },
        tabBarIndicatorStyle: {
          height: 4,
          backgroundColor: Colors.BLUE_700,
        },
        tabBarIndicatorContainerStyle: {
          paddingHorizontal: 10,
        },
        tabBarIconStyle: {},
        tabBarPressColor: '#00000000',
        tabBarContentContainerStyle: {},
        // eslint-disable-next-line react/no-unstable-nested-components
        tabBarLabel: ({focused}: any) => {
          return tabRoute?.name === 'Progress Reports' ? (
            <View style={[styles.row, styles.alignCenter]}>
              <Text
                numberOfLines={1}
                style={[styles.tab, focused ? styles.focused : {}]}>
                {tabRoute.name}
              </Text>
              <Separator width={10} />
              <BetaIcon />
            </View>
          ) : (
            <Text
              numberOfLines={1}
              style={[styles.tab, focused ? styles.focused : {}]}>
              {tabRoute.name}
            </Text>
          );
        },
      })}
      sceneContainerStyle={styles.sceneContainerStyle}
      screenListeners={tabBarListeners}>
      {children}
    </navigator.Navigator>
  );
};

const styles = StyleSheet.create({
  tab: {
    ...Typography.P3_BOLD,
    color: Colors.TEXT_TERTIARY,
    textTransform: 'none',
    textAlign: 'center',
  },
  focused: {
    color: Colors.TEXT_PRIMARY,
  },
  sceneContainerStyle: {
    flex: 1,
    height: '100%',
    backgroundColor: Colors.RAVEN_WHITE,
    overflow: 'scroll',
  },
  row: {
    flexDirection: 'row',
  },
  alignCenter: {
    alignItems: 'center',
    alignContent: 'center',
  },
});

export default TabBar;
