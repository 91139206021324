import {text, relation, field} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class Document extends Base {
  static table = 'documents';
  static associations = {
    patients: {type: 'belongs_to', key: 'patient_id'},
    users: {type: 'belongs_to', key: 'user_id'},
    // assessment_document: {type: 'has_many', foreignKey: 'document_id'},
  };

  @relation('patients', 'patient_id') patient;
  @relation('users', 'user_id') user;

  @text('document_type') documentType;
  @text('intake_type') intakeType;
  @text('file_name') fileName;
  @text('file_location') fileLocation;
  @text('local_file_location') localFileLocation;
  @text('sync_status') syncStatus;
  @field('starred') starred;

  // @lazy
  // assessments = this.collections
  //   .get('assessments')
  //   .query(
  //     Q.where('deleted_at', null),
  //     Q.on(
  //       'assessment_document',
  //       Q.and(Q.where('document_id', this.id), Q.where('deleted_at', null)),
  //     ),
  //   );
}
