import React, {useEffect, useMemo, useRef} from 'react';
import {FlatList, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {ReportComponent} from 'src/modules/patients/components';
import {useFieldArray, useFormContext, useWatch} from 'react-hook-form';
import ReportAuthorizationsComponent, {
  AuthorizationList,
} from 'src/modules/patients/components/report-authorizations-component';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {DataItem} from 'src/design-system';
import _ from 'lodash';
import moment from 'moment';
import SignatureInput from 'src/modules/session/components/signature-item';
import {Display as SignatureDisplay} from 'src/modules/session/components/signature-item';
import {Signature} from 'src/models';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useSelector} from 'react-redux';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {useTranslations} from 'src/providers/translation';
import {HelperText} from 'react-native-paper';
import {cptCodes} from 'src/common-utils/cptCodes';
import ViewShot from 'react-native-view-shot';
import {Separator} from 'src/common-components/atoms';

const defaultSignature = {
  entity: '',
  entity_id: '',
  signature: '',
  type: '',
  signatory: '',
};

const SummaryDisplay = ({progressReport, signatures, summaryRef}: any) => {
  const styles = useStyle();
  const {
    summary,
    summaryInclude,
    crisisPlan,
    crisisPlanInclude,
    transitionPlan,
    transitionPlanInclude,
    recommendations,
    recommendationsInclude,
    signatureInclude,
  } = progressReport;

  const overviewRef = useRef(null);
  const crisisPlanRef = useRef(null);
  const transitionPlanRef = useRef(null);
  const recommendationsRef = useRef(null);
  const authTableRef = useRef(null);
  const signaturesRef = useRef(null);

  const sections = [
    overviewRef,
    crisisPlanRef,
    transitionPlanRef,
    recommendationsRef,
    authTableRef,
    signaturesRef,
  ];

  useEffect(() => {
    for (const [index, ref] of sections.entries()) {
      summaryRef.current[index] = ref;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryRef]);

  const totalAuthorizations = useMemo(
    () =>
      cptCodes.reduce((total, cptCode) => total + progressReport[cptCode], 0),
    [progressReport],
  );

  return (
    <>
      {summaryInclude ||
      crisisPlanInclude ||
      transitionPlanInclude ||
      recommendationsInclude ||
      signatureInclude ? (
        <Text style={[Typography.H4, styles.paddingVertical]}>Summary</Text>
      ) : (
        <></>
      )}
      {summaryInclude ? (
        <ViewShot ref={overviewRef}>
          <DataItem>
            <Text style={[Typography.H5]}>Overview</Text>
            <Text style={[Typography.P3]}>{summary}</Text>
          </DataItem>
        </ViewShot>
      ) : (
        <></>
      )}

      {crisisPlanInclude ? (
        <ViewShot ref={crisisPlanRef}>
          <DataItem>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Crisis Plan
            </Text>
            <Text style={[Typography.P3]}>{crisisPlan}</Text>
          </DataItem>
        </ViewShot>
      ) : (
        <></>
      )}

      {transitionPlanInclude ? (
        <ViewShot ref={transitionPlanRef}>
          <DataItem>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Transition Plan / Discharge Criteria
            </Text>
            <Text style={[Typography.P3]}>{transitionPlan}</Text>
          </DataItem>
        </ViewShot>
      ) : (
        <></>
      )}

      {recommendationsInclude ? (
        <DataItem>
          <ViewShot ref={recommendationsRef}>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Recommendations
            </Text>
            <Text style={[Typography.P3]}>{recommendations}</Text>
          </ViewShot>
          <ViewShot ref={authTableRef}>
            <View style={[styles.paddingVertical, styles.container]}>
              <Text>
                <Text style={[Typography.H6]}>Start Date: </Text>
                <Text style={[Typography.P3]}>
                  {moment(progressReport.startDate).format('MM/DD/YYYY')}
                </Text>
              </Text>
              <Separator width={20} />
              <Text>
                <Text style={[Typography.H6]}>End Date: </Text>
                <Text style={[Typography.P3]}>
                  {moment(progressReport.endDate).format('MM/DD/YYYY')}
                </Text>
              </Text>
            </View>
            <View
              style={[
                styles.column,
                styles.boxShadow,
                styles.borderPrimary,
                styles.border,
              ]}>
              <View
                // eslint-disable-next-line react-native/no-inline-styles
                style={[styles.row, styles.justifySpaceBetween, {height: 70}]}>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View style={styles.marginAuto}>
                    <Text style={Typography.P3_BOLD}>Service Code</Text>
                  </View>
                </View>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View style={styles.marginAuto}>
                    <Text style={Typography.P3_BOLD}>Description</Text>
                  </View>
                </View>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View style={styles.marginAuto}>
                    <Text style={Typography.P3_BOLD}>Units Requested</Text>
                  </View>
                </View>
              </View>
              <FlatList
                data={AuthorizationList}
                keyExtractor={item => item.keyName}
                renderItem={({item}: any) => {
                  if (progressReport[item.keyName]) {
                    return (
                      <View
                        style={[
                          styles.row,
                          styles.justifySpaceBetween,
                          // eslint-disable-next-line react-native/no-inline-styles
                          {minHeight: 70},
                        ]}>
                        <View
                          style={[
                            styles.flex,
                            styles.borderPrimary,
                            styles.border,
                          ]}>
                          <View style={styles.marginAuto}>
                            <Text style={Typography.P3}>{item.cptCode}</Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.flex,
                            styles.borderPrimary,
                            styles.border,
                          ]}>
                          <View
                            style={[
                              styles.marginAuto,
                              styles.paddingHorizontal,
                              styles.alignCenter,
                            ]}>
                            <Text
                              style={[Typography.P3, styles.textAlignCenter]}>
                              {item.title}
                            </Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.flex,
                            styles.borderPrimary,
                            styles.border,
                          ]}>
                          <View style={styles.marginAuto}>
                            <Text>{progressReport[item.keyName]}</Text>
                          </View>
                        </View>
                      </View>
                    );
                  }
                }}
              />
              <View
                style={[
                  styles.row,
                  styles.justifySpaceBetween,
                  styles.boxShadow,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {height: 70},
                ]}>
                {/* eslint-disable-next-line react-native/no-inline-styles */}
                <View style={[{width: '50%'}]}>
                  <View
                    style={[
                      styles.marginAutoVertical,
                      styles.marginAutoLeft,
                      styles.marginRight,
                    ]}>
                    <Text style={Typography.P3_BOLD}>Total Requested</Text>
                  </View>
                </View>

                <View style={[styles.flex, styles.boxShadow]}>
                  <View style={styles.marginAuto}>
                    <Text style={Typography.P2}>
                      {totalAuthorizations} Units
                    </Text>
                  </View>
                </View>
              </View>
            </View>
          </ViewShot>
        </DataItem>
      ) : (
        <></>
      )}

      {signatureInclude ? (
        <ViewShot ref={signaturesRef}>
          <DataItem>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Signatures
            </Text>
            <View style={[styles.container]}>
              <View style={[styles.flex, styles.marginAutoRight]}>
                {signatures.map((signature: any) => {
                  return (
                    <SignatureDisplay key={signature?.id} value={signature} />
                  );
                })}
              </View>
            </View>
          </DataItem>
        </ViewShot>
      ) : (
        <></>
      )}
    </>
  );
};

const columnMap = {
  overview: 'summary',
  overview_include: 'summaryInclude',
  crisis_plan: 'crisisPlan',
  crisis_plan_include: 'crisisPlanInclude',
  transition_plan: 'transitionPlan',
  transition_plan_include: 'transitionPlanInclude',
  recommendations: 'recommendations',
  recommendations_include: 'recommendationsInclude',
  therapist_signature: 'therapistSignature',
  therapist_signature_2: 'therapistSignature2',
  parent_signature: 'parentSignature',
  signature_include: 'signatureInclude',
  startDate: 'startDate',
  endDate: 'endDate',
};

const Summary = ({progressReport, patient}: any) => {
  const database = useDatabase();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const translations = useTranslations();

  const {
    control,
    formState: {errors},
  } = useFormContext();

  const watchedValues = useWatch({
    control,
    name: 'summary',
  });

  const {remove: signaturesRemove, replace: signaturesReplace} = useFieldArray({
    control,
    name: 'signatures',
  });
  const signaturesWatch = useWatch({
    control,
    name: 'signatures',
  });

  useEffect(() => {
    const updatedValues = {};
    for (const [formValue, value] of Object.entries(watchedValues)) {
      const columnName = columnMap[formValue];
      if (!_.isEmpty(columnName)) {
        if (value !== '') {
          updatedValues[columnName] = value;
        }
      }
    }
    progressReport.updateEntity(updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  const styles = useStyle();

  return (
    <View style={[styles.flex]}>
      <ReportComponent
        title={'Summary'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'summary.overview'}
      />
      <ReportComponent
        title={'Crisis Plan'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'summary.crisis_plan'}
      />
      <ReportComponent
        title={'Transition Plan/Discharge Criteria'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'summary.transition_plan'}
      />
      <ReportAuthorizationsComponent
        title={'Recommendations'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'summary.recommendations'}
        control={control}
        progressReport={progressReport}
      />
      <View style={[styles.card, styles.padding]}>
        <Text style={[Typography.H4]}>Signatures</Text>
        <Text
          style={[
            Typography.P3,
            styles.paddingBottom,
            {color: Colors.GRAY_400},
          ]}>
          Select the information that you would like to include into the report.
        </Text>
        <View
          style={[
            styles.marginLVertical,
            styles.padding,
            styles.borderRadius,
            styles.backgroundColorWhite,
          ]}>
          <View style={[styles.column]}>
            <View style={[styles.flex, styles.marginRightAuto]}>
              {signaturesWatch.map((signature: any, index: number) => {
                return (
                  <View key={`signature-watch-${index}`} style={[styles.row]}>
                    <SignatureInput
                      index={index}
                      signature={signature}
                      patient={patient}
                      update={async (values: any) => {
                        const signatureFields = [...signaturesWatch];
                        if (values._id) {
                          const entity = await database
                            .get(Signature.table)
                            .find(values._id);

                          await entity.updateEntity({
                            entity: 'progressReport',
                            entityId: progressReport.id,
                            signature: values.signature,
                            type: values.type,
                            signatory: values.signatory,
                            deletedAt: values?.deletedAt ?? null,
                          });
                          signatureFields[index] = {
                            ...values,
                            updatedAt: new Date(),
                          };
                        } else {
                          const createdSignature = await database?.write(
                            async () => {
                              return database
                                ?.get(Signature.table)
                                .create(entity => {
                                  entity.partition = selectedGroup;
                                  entity.entity = 'progressReport';
                                  entity.entityId = progressReport.id;
                                  entity.signature = values.signature;
                                  entity.type = values.type;
                                  entity.signatory = values.signatory;

                                  entity.createdBy = userId;
                                  entity.updatedBy = userId;
                                });
                            },
                          );
                          signatureFields[index] = {
                            _id: createdSignature.id,
                            ...values,
                            updatedAt: new Date(),
                          };
                        }
                        signaturesReplace(signatureFields);
                      }}
                      remove={
                        index !== 0
                          ? async () => {
                              if (signature?._id) {
                                const entity = await database
                                  .get(Signature.table)
                                  .find(signature._id);

                                entity.delete();
                                const signatureFields = [...signaturesWatch];
                                signatureFields[index] = {
                                  ...signature,
                                  deletedAt: new Date(),
                                };
                                signaturesReplace(signatureFields);
                              } else {
                                signaturesRemove(index);
                              }
                            }
                          : undefined
                      }
                    />
                  </View>
                );
              })}
            </View>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <View style={[styles.alignSelfStart, styles.paddingLVertical]}>
                <RHButton
                  icon="plus-circle-outline"
                  mode="outlined"
                  color={Colors.RAVEN_WHITE}
                  textColor={Colors.RAVEN_BLACK}
                  onPress={() => {
                    const signatureFields = [
                      ...signaturesWatch,
                      {...defaultSignature, show: false},
                    ];
                    signaturesReplace(signatureFields);
                  }}>
                  {translations('add_new')}
                </RHButton>
              </View>
            </View>
            {errors?.signatures ? (
              <HelperText type="error" visible={true} style={[styles.helper]}>
                {'ⓧ ' + errors?.signatures?.message}
              </HelperText>
            ) : null}
          </View>
        </View>
        <View style={styles.paddingTop}>
          <HookFormSwitchInput
            label={'Include in Report'}
            name={'summary.signature_include'}
          />
        </View>
      </View>
    </View>
  );
};

export const Display = SummaryDisplay;

export default Summary;
