import React from 'react';
import {Platform, Keyboard, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';

interface Props {
  title: string;
  label: string;
  name: string;
  children?: JSX.Element[] | JSX.Element;
}

const ReportComponent = ({
  title = '',
  label = '',
  name,
  children = <></>,
}: Props) => {
  const styles = useStyle();
  const [bottom, setBottom] = React.useState(0);

  React.useEffect(() => {
    function onKeyboardChange(e) {
      if (e?.endCoordinates?.screenY <= e?.startCoordinates?.screenY) {
        setBottom(e.endCoordinates.height / 2);
      } else {
        setBottom(0);
      }
    }

    if (Platform.OS === 'ios') {
      const subscription = Keyboard.addListener(
        'keyboardWillChangeFrame',
        onKeyboardChange,
      );
      return () => subscription.remove();
    }

    const subscriptions = [
      Keyboard.addListener('keyboardDidHide', onKeyboardChange),
      Keyboard.addListener('keyboardDidShow', onKeyboardChange),
    ];
    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  return (
    <View style={[styles.card, styles.padding, styles.flex, {bottom}]}>
      <Text style={[Typography.H4]}>{title}</Text>
      <Text
        style={[Typography.P3, styles.paddingBottom, {color: Colors.GRAY_400}]}>
        {label}
      </Text>
      {children}
      <HookFormInput numberOfLines={10} name={name} label={title} />
      <HookFormSwitchInput
        label={'Include in Report'}
        name={`${name}_include`}
      />
    </View>
  );
};

export default ReportComponent;
