import {Model} from '@nozbe/watermelondb';
import {
  date,
  readonly,
  relation,
  text,
  writer,
} from '@nozbe/watermelondb/decorators';

export class AssessmentDocument extends Model {
  static table = 'assessment_document';
  static associations = {
    assessments: {type: 'belongs_to', key: 'assessment_id'},
    documents: {type: 'belongs_to', key: 'document_id'},
  };

  @relation('assessments', 'assessment_id') assessment;
  @relation('documents', 'document_id') document;

  @text('_partition') partition;
  @text('document_id') documentId;
  @text('created_by') createdBy;
  @readonly @date('created_at') createdAt;
  @text('updated_by') updatedBy;
  @readonly @date('updated_at') updatedAt;
  @text('deleted_by') deletedBy;
  @date('deleted_at') deletedAt;

  @writer async delete(user: string) {
    await this.update(entity => {
      entity.deletedBy = user;
      entity.deletedAt = new Date();
    });
  }
}
