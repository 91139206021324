import {date, field, relation} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgressReportProgram extends Base {
  static table = 'progress_report_program';
  static associations = {
    programs: {type: 'belongs_to', key: 'program_id'},
    progress_reports: {type: 'belongs_to', key: 'progress_report_id'},
  };

  @relation('programs', 'program_id') program;
  @relation('progress_reports', 'progress_report_id') progressReport;

  @field('program_id') programId;
  @field('program_include') programInclude;
  @field('program_info') programInfo;
  @field('graph_include') graphInclude;

  @date('start_date') startDate;
  @date('end_date') endDate;
}
