import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {DataItem, FormSectionHeader} from 'src/design-system';
import {Input as IntakeDateInput} from 'src/hook-form-inputs/intake-date';
import {useStyle} from 'src/providers/style';
import {Display as FirstNameDisplay} from 'src/hook-form-inputs/first-name';
import {Display as MiddleNameDisplay} from 'src/hook-form-inputs/middle-name';
import {Display as LastNameDisplay} from 'src/hook-form-inputs/last-name';
import {Display as PhoneNumberDisplay} from 'src/hook-form-inputs/phone-number';
import {Display as EmailDisplay} from 'src/hook-form-inputs/email';
import {Display as AddressDisplay} from 'src/hook-form-inputs/address';
import UserInput from 'src/hook-form-inputs/staff';
import {useFormContext, useWatch} from 'react-hook-form';
import {Typography} from 'src/styles';
import moment from 'moment';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {compose} from 'recompose';
import {Credential, User} from 'src/models';
import {of} from 'rxjs';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import CredentialSelector from 'src/hook-form-inputs/credential-selector';
import CredentialForm from 'src/modules/patients/components/credential-form';
import {useDatabase} from '@nozbe/watermelondb/hooks';

export const Value = (value: string) => {
  return value;
};

const ClinicalFormDisplay = ({primary, referring, staff, ...values}: any) => {
  const styles = useStyle();
  return (
    <>
      <FormSectionHeader title={'Clinical Information'} />
      <DataItem>
        <Text style={[Typography.H6]}>Intake Date</Text>
        <Text style={[Typography.P3]}>
          {values?.intakeDate > 0
            ? moment(values?.intakeDate).format('ll')
            : ''}
        </Text>
      </DataItem>
      <DataItem>
        <Text style={[Typography.H6]}>Assigned Staff</Text>
        <Text style={[Typography.P3]}>
          {staff?.firstName} {staff?.lastName}
        </Text>
      </DataItem>
      <View style={[styles.container]}>
        <View style={[styles.flex, styles.column]}>
          <FormSectionHeader title={'Primary Physician'} />
          <FirstNameDisplay value={primary?.firstName} />
          <MiddleNameDisplay value={primary?.middleName} />
          <LastNameDisplay value={primary?.lastName} />
          <PhoneNumberDisplay value={primary?.mobilePhone} />
          <EmailDisplay value={primary?.email} />
          <AddressDisplay value={primary?.address || {}} />
        </View>
        <View style={[styles.flex, styles.column]}>
          <FormSectionHeader title={'Referring Physician'} />
          {!values.sameAsPrimary ? (
            <>
              <FirstNameDisplay value={referring?.firstName} />
              <LastNameDisplay value={referring?.lastName} />
              <PhoneNumberDisplay value={referring?.mobilePhone} />
              <EmailDisplay value={referring?.email} />
              <AddressDisplay value={referring?.address || {}} />
            </>
          ) : (
            <Text style={[Typography.P3]}>Same as Primary Physician</Text>
          )}
        </View>
      </View>
    </>
  );
};

const ClinicalFormInput = () => {
  const styles = useStyle();
  const {control, setValue} = useFormContext();
  const database = useDatabase();

  const sameAsPrimary = useWatch({
    name: 'sameAsPrimary',
    control,
  });

  const primaryId = useWatch({
    name: 'primaryId',
    control,
  });

  const referringId = useWatch({
    name: 'referringId',
    control,
  });

  const watchedReferring = useWatch({
    name: 'referring',
    control,
  });
  const setReferring = async () => {
    if (referringId !== 'other') {
      const referring = await database.get(Credential.table).find(referringId);
      if (referring) {
        setValue('referring', {
          firstName: referring.firstName,
          middleName: referring.middleName,
          lastName: referring.lastName,
          mobilePhone: referring.mobilePhone,
          email: referring.email,
          address: referring.address || {},
          tin: referring.tin,
          npi: referring.npi,
          ssn: referring.ssn,
          taxonomy: [],
        });
        setValue('referring.address', referring.address);
      }
    } else {
      setValue('referring', watchedReferring);
      setValue('referring.address', {});
    }
  };

  useEffect(() => {
    if (referringId) {
      setReferring();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referringId]);

  return (
    <>
      <View style={[styles.paddingHorizontal]}>
        <FormSectionHeader title={'Clinical Information'} />
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <IntakeDateInput />
        </View>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <UserInput multiple={false} />
        </View>
      </View>
      <View style={[styles.separator, styles.marginVertical]} />
      <View style={[styles.paddingHorizontal]}>
        <FormSectionHeader title={'Primary Physician'} />
      </View>
      <View style={[styles.container, styles.width]}>
        <View style={[styles.flex, styles.paddingHorizontal]}>
          <CredentialSelector override={'primaryId'} />
        </View>
      </View>
      {primaryId === 'other' ? (
        <View style={[styles.paddingHorizontal]}>
          <CredentialForm name={'primary'} />
        </View>
      ) : null}
      <View style={[styles.separator, styles.marginVertical]} />
      <View style={[styles.paddingHorizontal, styles.paddingLBottom]}>
        <FormSectionHeader title={'Referring Physician'} />
        <View style={styles.row}>
          <HookFormSwitchInput
            name={'sameAsPrimary'}
            label={'Same as Primary Physician'}
          />
        </View>
      </View>
      {!sameAsPrimary ? (
        <>
          <View style={[styles.container, styles.width]}>
            <View style={[styles.flex, styles.paddingHorizontal]}>
              <CredentialSelector override={'referringId'} />
            </View>
          </View>
          <View style={[styles.paddingHorizontal]}>
            {referringId ? <CredentialForm name={'referring'} /> : null}
          </View>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export const Display = compose(
  withDatabase,
  withObservables([], ({staffId, database}: any) => ({
    staff: staffId ? database.get(User.table).findAndObserve(staffId) : of({}),
  })),
)(ClinicalFormDisplay);
export const Input = ClinicalFormInput;

export default ClinicalFormInput;
