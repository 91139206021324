import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {ReportComponent} from 'src/modules/patients/components';
import {useFormContext, useWatch} from 'react-hook-form';
import {DataItem} from 'src/design-system';
import {Typography} from 'src/styles';

const BackgroundDisplay = ({progressReport}: any) => {
  const styles = useStyle();
  const {
    backgroundInfo,
    backgroundInfoInclude,
    medicalInfo,
    medicalInfoInclude,
  } = progressReport;
  return (
    <>
      {backgroundInfoInclude ? (
        <View style={[styles.paddingMVertical]}>
          <DataItem>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Background Information
            </Text>
            <Text style={[Typography.P3]}>{backgroundInfo}</Text>
          </DataItem>
        </View>
      ) : (
        <></>
      )}
      {medicalInfoInclude ? (
        <View style={[styles.paddingMVertical]}>
          <DataItem>
            <Text style={[Typography.H5, styles.paddingSMVertical]}>
              Medical Information
            </Text>
            <Text style={[Typography.P3]}>{medicalInfo}</Text>
          </DataItem>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

const columnMap = {
  background_info: 'backgroundInfo',
  background_info_include: 'backgroundInfoInclude',
  medical_info: 'medicalInfo',
  medical_info_include: 'medicalInfoInclude',
};

const Background = ({progressReport}: any) => {
  const styles = useStyle();
  const {control} = useFormContext();
  const watchedValues = useWatch({
    control,
    name: 'background',
  });

  useEffect(() => {
    const updatedValues = {};
    for (const [formValue, value] of Object.entries(watchedValues)) {
      const columnName = columnMap[formValue];
      if (value !== '') {
        updatedValues[columnName] = value;
      }
    }
    progressReport.updateEntity(updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  return (
    <View style={[styles.flex]}>
      <ReportComponent
        title={'Background'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'background.background_info'}
      />
      <ReportComponent
        title={'Medical'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'background.medical_info'}
      />
    </View>
  );
};

export const Display = BackgroundDisplay;

export default Background;
