import React, {useEffect, useState} from 'react';
import moment from 'moment';
import _ from 'lodash';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import Graph from '../../graph';
import {endOfDay, startOfDay} from 'date-fns';

const TrialByTrialProgressGraph = ({
  program = {},
  data = [],
  sets = [],
  envs = [],
  sessions = [],
  target = {},
  children,
  title = null,
  extras = {},
  startDate = '',
  endDate = '',
  byDay = false,
  filteredEnvItems = [],
  shouldShowSummary = false,
  summaryOnly = false,
  targets = [],
  events = [],
  allSets = {},
  analyzeSets = () => {},
  recentSetsByTargetId = {},
}: any) => {
  const database = useDatabase();
  const [analysis, setAnalysis] = useState<any>({});
  const sortedEnvs = envs.filter(
    env =>
      moment(env.date).isSameOrAfter(moment(startOfDay(new Date(startDate)))) &&
      moment(env.date).isSameOrBefore(moment(endOfDay(new Date(endDate)))),
  );
  const groupedSessions = _.groupBy(sessions, 'sessionDate');
  const dailySessions = Object.values(groupedSessions).map(obj => {
    return obj[0];
  });
  const analyzedSets = byDay ? dailySessions : sets;

  const calculateData = async () => {
    const dataArr = [...analyzedSets, ...sortedEnvs]
      .sort((a, b) => {
        const dateA = a.startTimestamp || a.date;
        const dateB = b.startTimestamp || b.date;

        return new Date(dateA) - new Date(dateB);
      })
      .map(datum => {
        if (data[datum?.sessionId]) {
          return data[datum?.sessionId];
        } else if (data[datum?.sessionDate]) {
          return data[datum?.sessionDate];
        } else {
          return {
            ...datum,
            startTimestamp: datum?.date,
            type: datum?.type,
            title: datum?.title,
            description: datum?.description,
          };
        }
      });

    let envLineData = [];
    let lines = [];
    let phaseChanges = [];
    let currentState = {
      state: '',
      aggregate: 0,
      num: 0,
      index: 1,
    };

    const environmentalObjects = _.groupBy(envs, (env: any) => {
      return moment(env.date).format('YYYY-MM-DD');
    });

    let groupedKeys = {};

    for (const key in environmentalObjects) {
      if (
        moment(key).isSameOrAfter(moment(startOfDay(new Date(startDate)))) &&
        moment(key).isSameOrBefore(moment(endOfDay(new Date(endDate))))
      ) {
        const environmentalItems = _.groupBy(
          environmentalObjects[key],
          (env: any) => env?.type,
        );
        groupedKeys[key] = {
          dates: [...(groupedKeys[key]?.dates || []), key],
          change: [
            ...(environmentalItems?.change ? environmentalItems.change : []),
          ],
          factor: [
            ...(environmentalItems?.factor ? environmentalItems.factor : []),
          ],
        };
      }
    }

    for (const [i, session] of dataArr.entries()) {
      if (session.sessionId || session.sessionIds) {
        const pointsOnly = lines.filter(line => line.type === 'point');
        let latestLine = pointsOnly?.[pointsOnly.length - 1] || {
          state: '',
          points: [],
          type: 'point',
        };

        let collectors = [];
        if (session?.collectors) {
          collectors = await Promise.all(
            session?.collectors?.map(async (collectorId: string) => {
              if (collectorId !== 'System') {
                const collector = await database.get('users').find(collectorId);
                return `${collector?.firstName} ${collector?.lastName}`;
              } else {
                return collectorId;
              }
            }),
          );
        }

        if (latestLine.state === session.state) {
          latestLine.points.push({
            x: i + 1,
            y: isNaN(session?.average) ? 0 : session?.average,
            date: session?.startTimestamp,
            target: target?.name,
            collectors,
          });
          currentState = {
            ...currentState,
            aggregate: currentState.aggregate + i + 1,
            num: currentState.num + 1,
          };
        } else {
          phaseChanges.push({
            line: currentState.index,
            x: currentState.aggregate / currentState.num,
            state: latestLine.state,
          });
          latestLine = {
            state: session.state,
            points: [
              {
                x: i + 1,
                y: session?.average,
                date: session?.startTimestamp,
                target: target?.name,
                collectors,
              },
            ],
            type: 'point',
          };
          currentState = {
            state: session.state,
            aggregate: i + 1,
            num: 1,
            index: i + 1,
          };
        }

        if (latestLine.points.length === 1) {
          lines.push(latestLine);
        } else {
          pointsOnly[pointsOnly.length - 1] = latestLine;
        }
      } else if (session.type === 'change') {
        lines.push({
          x: i + 1,
          y: 50,
          type: 'change',
          values: {
            [moment(session.startTimestamp).format('YYYY-MM-DD')]: [
              {
                date: session.startTimestamp,
                title: session.title,
                description: session.description,
              },
            ],
          },
        });
      } else if (session.type === 'factor') {
        lines.push({
          x: i + 1,
          y: 50,
          type: 'factor',
          values: {
            [moment(session.startTimestamp).format('YYYY-MM-DD')]: [
              {
                date: session.startTimestamp,
                title: session.title,
                description: session.description,
              },
            ],
          },
        });
      }
    }
    phaseChanges.push({
      line: currentState.index,
      x: currentState.aggregate / currentState.num,
      state: currentState.state,
    });

    setAnalysis({data: dataArr, envLineData, phaseChanges, lines});
  };

  useEffect(() => {
    calculateData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.id, data, program, startDate, endDate, byDay]);
  return (
    <Graph
      yAxisLabel={'% Correct'}
      slot={children}
      data={analysis.data}
      program={program}
      envs={analysis.envLineData}
      phaseChanges={analysis.phaseChanges}
      lines={analysis.lines}
      title={title}
      extras={extras}
      targets={targets}
      events={events}
      sets={sets}
      filteredEnvItems={filteredEnvItems}
      shouldShowSummary={shouldShowSummary}
      summaryOnly={summaryOnly}
      allSets={allSets}
      analyzeSets={analyzeSets}
      recentSetsByTargetId={recentSetsByTargetId}
    />
  );
};

export default React.memo(TrialByTrialProgressGraph);
