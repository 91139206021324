import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {ReportComponent} from 'src/modules/patients/components';
import {useFormContext, useWatch} from 'react-hook-form';
import {Typography} from 'src/styles';
import {DataItem} from 'src/design-system';

const CaregiverDisplay = ({progressReport}: any) => {
  const styles = useStyle();
  const {caregiverGuidanceInfo, caregiverGuidanceInfoInclude} = progressReport;
  return caregiverGuidanceInfoInclude ? (
    <>
      <Text style={[Typography.H4, styles.paddingVertical]}>
        Parent Training
      </Text>
      <DataItem>
        <Text style={[Typography.H5, styles.paddingSMVertical]}>
          Caregiver Guidance
        </Text>
        <Text style={[Typography.P3]}>{caregiverGuidanceInfo}</Text>
      </DataItem>
    </>
  ) : (
    <></>
  );
};

const columnMap = {
  caregiver_guidance: 'caregiverGuidanceInfo',
  caregiver_guidance_include: 'caregiverGuidanceInfoInclude',
};

const Caregiver = ({progressReport}: any) => {
  const styles = useStyle();
  const {control} = useFormContext();
  const watchedValues = useWatch({
    control,
    name: 'caregiver',
  });

  useEffect(() => {
    const updatedValues = {};
    for (const [formValue, value] of Object.entries(watchedValues)) {
      const columnName = columnMap[formValue];
      if (value !== '') {
        updatedValues[columnName] = value;
      }
    }
    progressReport.updateEntity(updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  return (
    <View style={styles.flex}>
      <ReportComponent
        title={'Caregiver Guidance'}
        label={
          'Select the information that you would like to include into the report.'
        }
        name={'caregiver.caregiver_guidance'}
      />
    </View>
  );
};

export const Display = CaregiverDisplay;

export default Caregiver;
