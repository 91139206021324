import React, {useEffect, useState} from 'react';
import {
  DrawerContentScrollView,
  DrawerItem as ReactNavigationDrawerItem,
} from '@react-navigation/drawer';
import {Linking, Platform, Text, TouchableOpacity, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import {Colors, Typography} from 'src/styles';
import {logout} from 'src/redux/auth';
import {useDispatch, useSelector} from 'react-redux';
import {Q} from '@nozbe/watermelondb';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import FileImage from 'src/design-system/file-image';
import Menu from 'src/design-system/menu';
import {APP_VERSION} from 'react-native-dotenv';
import {useStyle} from 'src/providers/style';
import DrawerContentItem from '../drawer-content-item';
import DrawerItem from '../drawer-item';
import {useTranslations} from 'src/providers/translation';
import {Modal} from 'src/design-system';
import {Button} from 'react-native-paper';
import {RHButton} from 'src/common-components/custom-ui-helpers';

const currentVersion = `V. ${APP_VERSION}`;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isWeb = Platform.OS === 'web';

const DrawerContent = ({state, navigation}: any) => {
  const dispatch = useDispatch();
  const database = useDatabase();
  const styles = useStyle();
  const translations = useTranslations();

  const {userId, selectedGroup} = useSelector(
    rootState => rootState.authentication,
  );
  const [instance, setInstance] = useState({
    id: '',
    name: '',
    logo: '',
    accountNumber: '',
  });
  const [userProfile, setUserProfile] = useState({
    id: '',
    firstName: '',
    lastName: '',
    permission: '',
  });
  const [role, setRole] = useState({
    name: '',
  });
  const [show, setShow] = useState(false);

  const fetchState = async () => {
    if (userId && selectedGroup) {
      const instances = await database
        .get('instances')
        .query(Q.where('_partition', selectedGroup))
        .fetch();
      if (instances.length) {
        setInstance(instances?.[0]);
      }
      if (userId) {
        const user = await database.get('users').find(userId);
        setUserProfile(user);
        setRole(await user.role);
      }
    }
  };
  useEffect(() => {
    fetchState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, selectedGroup]);

  const options = [
    {
      onPress: () => {
        setShow(true);
      },
      title: translations('support'),
      icon: 'help-box',
    },
    {
      onPress: async () => {
        await database.write(async () => {
          if (!database._isBeingReset) {
            await database.unsafeResetDatabase();
          }
        });
        dispatch(logout());
        navigation.navigate('Login');
      },
      title: translations('logout'),
      icon: 'logout',
    },
  ];

  return (
    <>
      <Modal
        show={[show, setShow]}
        title={'Support'}
        footer={
          <RHButton secondary mode="contained" onPress={() => setShow(false)}>
            Close
          </RHButton>
        }>
        <View style={[styles.row, styles.alignCenter]}>
          <Text style={[Typography.P3, styles.paddingSMTop]}>
            General Product Support:
          </Text>
          <Button
            onPress={() => Linking.openURL('mailto:support@ravenhealth.com')}
            style={[styles.backgroundColorWhite, styles.textColorLink]}
            labelStyle={styles.textColorLink}>
            support@ravenhealth.com
          </Button>
        </View>
        <View style={[styles.row, styles.alignCenter]}>
          <Text style={[Typography.P3, styles.paddingSMTop]}>
            Billing Support:
          </Text>
          <Button
            onPress={() =>
              Linking.openURL('mailto:billingsupport@ravenhealth.com')
            }
            style={[styles.backgroundColorWhite, styles.textColorLink]}
            labelStyle={styles.textColorLink}>
            billingsupport@ravenhealth.com
          </Button>
        </View>
        <View style={[styles.row, styles.alignCenter]}>
          <Text style={[Typography.P3, styles.paddingSMTop]}>
            Training Video/Guides:
          </Text>
          <Button
            onPress={() =>
              Linking.openURL(
                'https://info.ravenhealth.com/customer-knowledge-nest',
              )
            }
            labelStyle={styles.textColorLink}
            style={[styles.backgroundColorWhite]}>
            Knowledge Nest
          </Button>
        </View>
        <View style={[styles.row, styles.alignCenter]}>
          <Text style={[Typography.P3, styles.paddingSMTop]}>
            Account Deletion:
          </Text>
          <Button
            onPress={() => {
              navigation.navigate('Account');
              setShow(false);
            }}
            labelStyle={styles.textColorLink}
            style={[styles.backgroundColorWhite]}>
            DATA DELETION POLICY
          </Button>
        </View>
      </Modal>
      <DrawerContentScrollView>
        <ReactNavigationDrawerItem
          label={({focused}) =>
            DrawerItem({
              label: '',
              focused,
              icon: 'close',
            })
          }
          onPress={() => {
            navigation.toggleDrawer();
          }}
        />

        <View
          style={[
            styles.borderRadiusSM,
            styles.backgroundColorPrimary800,
            styles.paddingL,
            styles.marginM,
            styles.row,
            styles.alignCenter,
          ]}>
          {instance?.logo ? (
            <View
              style={[
                styles.alignCenter,
                styles.justifyCenter,
                styles.marginMRight,
                styles.overflowHidden,
                styles.borderRadius,
                styles.width50,
                styles.height50,
              ]}>
              <FileImage src={instance?.logo} />
            </View>
          ) : null}
          {role?.organizationView ? (
            <TouchableOpacity
              style={[styles.flex, styles.column, styles.justifyCenter]}
              onPress={() =>
                navigation.navigate('OrganizationProfile', {
                  id: instance.id,
                })
              }>
              <Text
                style={[
                  Typography.P3,
                  styles.textColorWhite,
                  styles.marginMBottom,
                ]}>
                {instance.name}
              </Text>
              <Text style={[Typography.CAPTION, styles.textColorPrimary300]}>
                {translations('account')} {instance?.accountNumber}
              </Text>
            </TouchableOpacity>
          ) : (
            <View style={[styles.flex, styles.column, styles.justifyCenter]}>
              <Text
                style={[
                  Typography.P3,
                  styles.textColorWhite,
                  styles.marginMBottom,
                ]}>
                {instance.name}
              </Text>
              <Text style={[Typography.CAPTION, styles.textColorPrimary300]}>
                {translations('account')} {instance?.accountNumber}
              </Text>
            </View>
          )}
          <TouchableOpacity
            onPress={() => navigation.navigate('OrganizationSelection')}>
            <Icon
              size={24}
              name={'compare-horizontal'}
              color={Colors.RAVEN_WHITE}
            />
          </TouchableOpacity>
        </View>

        <Text
          key={'Clinical Execution'}
          style={[
            Typography.CAPTION_BOLD,
            styles.paddingLHorizontal,
            styles.paddingTop,
            styles.paddingMBottom,
            styles.textColorPrimary300,
          ]}>
          {translations('clinical_execution')}
        </Text>
        <DrawerContentItem
          navigationKey={'Dashboard'}
          label={translations('launchpad')}
          icon={'rocket-outline'}
          navigation={navigation}
          state={state}
        />
        {role?.appointmentView ? (
          <DrawerContentItem
            navigationKey={'Schedule'}
            label={translations('schedule')}
            icon={'calendar'}
            navigation={navigation}
            state={state}
          />
        ) : null}
        {role?.patientView || role?.userView || role?.reportView ? (
          <Text
            key={'Practice Management'}
            style={[
              Typography.CAPTION_BOLD,
              styles.paddingLHorizontal,
              styles.paddingTop,
              styles.paddingMBottom,
              styles.textColorPrimary300,
            ]}>
            {translations('practice_management')}
          </Text>
        ) : null}
        {role?.patientView ? (
          <DrawerContentItem
            navigationKey={'PatientList'}
            label={translations('clients')}
            icon={'account-circle-outline'}
            navigation={navigation}
            state={state}
          />
        ) : null}

        {role?.userView ? (
          <DrawerContentItem
            navigationKey={'Users'}
            label={translations('staff')}
            icon={'account-supervisor'}
            navigation={navigation}
            state={state}
          />
        ) : null}

        {role?.reportView ? (
          <DrawerContentItem
            navigationKey={'Reports'}
            label={translations('reports')}
            icon={'chart-bar'}
            navigation={navigation}
            state={state}
            // beta={false}
          />
        ) : (
          <></>
        )}

        {role?.programView ||
        role?.promptView ||
        role?.tagView ||
        role?.roleView ? (
          <Text
            key={'Admin'}
            style={[
              Typography.CAPTION_BOLD,
              styles.paddingLHorizontal,
              styles.paddingTop,
              styles.paddingMBottom,
              styles.textColorPrimary300,
            ]}>
            {translations('admin')}
          </Text>
        ) : null}
        {role?.programView ? (
          <DrawerContentItem
            navigationKey={'Programs'}
            label={translations('programs')}
            icon={'grid'}
            navigation={navigation}
            state={state}
          />
        ) : null}
        {role?.promptView || role?.tagView || role?.roleView ? (
          <DrawerContentItem
            navigationKey={'Settings'}
            label={translations('settings')}
            icon={'palette'}
            onPress={() =>
              navigation.navigate('OrganizationProfile', {
                id: instance.id,
                screen: 'Prompts',
              })
            }
            navigation={navigation}
            state={state}
          />
        ) : null}
        {instance.billing && role?.billingView ? (
          <DrawerContentItem
            navigationKey={'Billing'}
            label={'Billing'}
            icon={'cash'}
            onPress={() => navigation.navigate('Billing')}
            navigation={navigation}
            state={state}
          />
        ) : null}
        {role?.auditView && role?.auditSessionView ? (
          <DrawerContentItem
            navigationKey={'Session Audit'}
            label={'Session Audit'}
            icon={'history'}
            onPress={() =>
              navigation.navigate('Audit', {
                screen: 'Audit',
              })
            }
            navigation={navigation}
            state={state}
          />
        ) : null}
        <Text
          key={'More'}
          style={[
            Typography.CAPTION_BOLD,
            styles.paddingLHorizontal,
            styles.paddingTop,
            styles.paddingMBottom,
            styles.textColorPrimary300,
          ]}>
          {translations('more')}
        </Text>
        <DrawerContentItem
          navigationKey={'Profile'}
          label={translations('profile')}
          icon={'account-circle'}
          state={state}
          onPress={() => {
            navigation.navigate('UserProfile', {
              practitionerId: userProfile?.id,
            });
          }}
        />
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.marginVertical,
            styles.paddingVertical,
            styles.paddingLHorizontal,
          ]}>
          <View style={[styles.column]}>
            <Text
              style={[
                Typography.P3,
                styles.marginMBottom,
                styles.textColorWhite,
              ]}>
              {`${userProfile.firstName} ${userProfile.lastName}`}
            </Text>
            <Text
              style={[
                Typography.CAPTION,
                styles.textColorPrimary300,
              ]}>{`${role?.name}`}</Text>
          </View>
          <View style={[styles.column, styles.alignEnd]}>
            <Menu
              anchor={
                <Icon size={24} name={'cog'} color={Colors.PRIMARY_300} />
              }
              options={options}
            />
            <Text
              style={[
                Typography.CAPTION,
                styles.textColorPrimary300,
                styles.marginMTop,
              ]}>
              {currentVersion}
            </Text>
          </View>
        </View>
      </DrawerContentScrollView>
    </>
  );
};

export default DrawerContent;
