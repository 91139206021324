import React, {forwardRef, ReactNode, useEffect, useRef, useState} from 'react';
import {
  Checkbox,
  HelperText,
  Searchbar,
  TextInput,
  TouchableRipple,
} from 'react-native-paper';
import {
  FlatList,
  Modal,
  Platform,
  SectionList,
  Text,
  TouchableOpacity,
  View,
} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {fuzzy} from 'src/common-utils/fuzzy';
import {useDimensions} from '@react-native-community/hooks';
import {useTranslations} from 'src/providers/translation';

interface Props {
  label: string;
  required?: boolean;
  multiple?: boolean;
  searchable?: boolean;
  items?: {
    label: string;
    value: any | string | number;
    custom?: ReactNode;
  }[];
  sections?: {
    label: string;
    items: {
      label: string;
      value: any | string | number;
      custom?: ReactNode;
    }[];
  }[];
  onChange: (value: string | string[]) => void;
  value: string | string[];
  selectAll?: boolean;
  error: any;
  style?: any;
  showHelper?: boolean;
  disabled?: boolean;
  helper?: string;
  canCancel?: boolean;
}

const SelectInput = forwardRef(
  (
    {
      label,
      multiple = false,
      required = false,
      searchable = true,
      selectAll = false,
      items = [],
      sections = [],
      onChange,
      value,
      error,
      helper,
      showHelper = true,
      disabled = false,
      canCancel = false,
    }: Props,
    ref,
  ) => {
    const styles = useStyle();
    const dimensions = useDimensions();
    const translations = useTranslations();

    const flatListRef = useRef();
    const touchable = useRef();

    const [open, setOpen] = useState(false);
    const [searchedItems, setSearchedItems] = useState<
      | {
          label: string;
          value: any | string | number;
          custom?: ReactNode;
        }[]
      | {
          label: string;
          items: {
            label: string;
            value: any | string | number;
            custom?: ReactNode;
          }[];
        }[]
    >([]);
    const [searchedFilter, setSearchedFilter] = useState<string>('');
    const [displayValue, setDisplayValue] = useState('');
    const [inputLayout, setInputLayout] = useState({
      width: 0,
      left: 0,
      top: 0,
    });

    const onLayout = (event: any) => {
      if (Platform.OS === 'web') {
        setInputLayout({
          width: event.nativeEvent.layout.width,
          left: event.nativeEvent.layout.left,
          top: event.nativeEvent.layout.top,
        });
      } else {
        touchable.current?.measure((fx, fy, width, height, px, py) => {
          setInputLayout({
            width: width,
            left: px,
            top: py + height,
          });
        });
      }
    };

    const initialScroll = () => {
      const scrollIndex = value
        ? searchedItems.findIndex(item => item.value === value)
        : 0;
      if (scrollIndex !== -1) {
        flatListRef.current?.scrollToIndex({
          index: scrollIndex,
          animated: false,
        });
      }
    };

    useEffect(() => {
      if (searchedFilter !== '') {
        if (sections?.length > 0) {
          const searchedSections = [];
          if (sections.length > 0) {
            for (const section of sections) {
              if (
                section.data.find(item => fuzzy(item.label, searchedFilter))
              ) {
                searchedSections.push({
                  label: section?.label,
                  data: section.data.filter(item =>
                    fuzzy(item.label, searchedFilter),
                  ),
                });
              }
            }
          }
          setSearchedItems(searchedSections);
        } else {
          setSearchedItems(
            items.filter(item => fuzzy(item.label, searchedFilter)),
          );
        }
      } else {
        setSearchedItems(sections?.length > 0 ? sections : items);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchedFilter, items]);

    useEffect(() => {
      if (multiple) {
        if (value.length > 0) {
          setDisplayValue(
            value
              .map(val => {
                if (sections.length > 0) {
                  for (const section of sections) {
                    const _label = section.data.find(
                      _ => _.value === val,
                    )?.label;
                    if (_label) {
                      return _label;
                    }
                  }
                }
                return items.find(_ => _.value === val)?.label;
              })
              .join(', '),
          );
        } else {
          setDisplayValue('');
        }
      } else {
        const _label = items.find(_ => _.value === value)?.label;
        if (_label) {
          setDisplayValue(_label);
        } else {
          setDisplayValue('');
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
      <>
        <TouchableRipple
          onPress={async e => {
            if (Platform.OS === 'web') {
              setInputLayout({
                ...inputLayout,
                left: e.target.getBoundingClientRect().x,
                top:
                  e.target.getBoundingClientRect().y +
                  e.target.getBoundingClientRect().height +
                  (dimensions.window.height - e.clientY <
                  (searchable ? 266 : 200)
                    ? -(searchable ? 266 : 200)
                    : 0),
              });
            } else {
              e.target.measureInWindow((x: number, y: number) => {
                setInputLayout({
                  ...inputLayout,
                  left: x,
                  top:
                    y +
                    66 +
                    (dimensions.window.height - y < (searchable ? 266 : 200)
                      ? -(searchable ? 266 : 200)
                      : 0),
                });
              });
            }
            setOpen(true);
          }}
          onLayout={onLayout}
          accessibilityLabel={label}
          disabled={disabled}>
          <View
            ref={touchableRef => (touchable.current = touchableRef)}
            pointerEvents={
              !multiple && value && canCancel ? 'box-none' : 'none'
            }>
            <TextInput
              ref={ref}
              value={displayValue}
              mode={'outlined'}
              label={label + (required ? '*' : '')}
              placeholder={label + (required ? '*' : '')}
              pointerEvents={
                !multiple && value && canCancel ? 'box-none' : 'none'
              }
              left={
                !multiple && value && canCancel ? (
                  <TextInput.Icon
                    name={'close'}
                    size={20}
                    color={Colors.GRAY_600}
                    style={[styles.paddingSMTop]}
                    onPress={() => onChange('')}
                  />
                ) : null
              }
              right={<TextInput.Icon name={open ? 'menu-up' : 'menu-down'} />}
              style={styles.input}
              disabled={disabled}
            />
          </View>
        </TouchableRipple>

        <Modal visible={open} transparent>
          <View
            style={[
              styles.positionRelative,
              styles.width,
              styles.flex,
              styles.elevation,
            ]}>
            <TouchableOpacity
              onPress={() => setOpen(false)}
              style={[
                styles.width,
                styles.height,
                styles.positionAbsolute,
                styles.backgroundColorTransparent,
                styles.top0,
                styles.left0,
              ]}
            />
            <View
              style={[
                styles.positionAbsolute,
                styles.backgroundColorWhite,
                styles.borderRadiusSM,
                styles.elevation,
                styles.overflowHidden,
                {
                  top: inputLayout.top,
                  width: inputLayout.width,
                  left: inputLayout.left,
                },
                // eslint-disable-next-line react-native/no-inline-styles
                Platform.OS === 'web' ? {overflowY: 'auto'} : null,
              ]}>
              {searchable ? (
                <View style={[styles.paddingM]}>
                  <Searchbar
                    value={searchedFilter}
                    onChangeText={setSearchedFilter}
                    placeholder={translations('search')}
                    inputStyle={[Typography.P2]}
                    style={[
                      styles.border,
                      styles.borderColorPrimary300,
                      styles.elevation0,
                    ]}
                  />
                </View>
              ) : null}
              {selectAll && multiple ? (
                <TouchableRipple
                  onPress={() => {
                    if (value?.length === searchedItems?.length) {
                      onChange([]);
                    } else {
                      onChange(searchedItems?.map(item => item.value));
                    }
                  }}>
                  <View style={[styles.row]}>
                    {multiple ? (
                      <Checkbox.Android
                        status={
                          value?.length === searchedItems?.length
                            ? 'checked'
                            : 'unchecked'
                        }
                        color={Colors.RAVEN_BLACK}
                        uncheckedColor={Colors.RAVEN_BLACK}
                      />
                    ) : null}
                    <Text
                      style={[
                        styles.paddingL,
                        Typography.LABEL,
                        value?.length === searchedItems?.length
                          ? styles.textColorSecondaryColor
                          : styles.textColorPrimary,
                      ]}>
                      {translations('select_all')}
                    </Text>
                  </View>
                </TouchableRipple>
              ) : null}
              {searchedItems.length > 0 ? (
                <>
                  {sections?.length > 0 ? (
                    <SectionList
                      onLayout={initialScroll}
                      ref={flatListRef}
                      sections={searchedItems}
                      initialScrollIndex={
                        multiple
                          ? 0
                          : value
                          ? searchedItems.findIndex(
                              item => item.value === value,
                            )
                          : 0
                      }
                      renderItem={({item}: any) => {
                        return (
                          <TouchableRipple
                            onPress={() => {
                              if (multiple) {
                                if (value?.indexOf(item.value) !== -1) {
                                  onChange(
                                    value?.filter(val => val !== item.value),
                                  );
                                } else {
                                  onChange([...value, item.value]);
                                }
                              } else {
                                onChange(item.value);
                                setOpen(false);
                              }
                            }}>
                            <View style={[styles.row]}>
                              {multiple ? (
                                <Checkbox.Android
                                  status={
                                    value?.indexOf(item.value) !== -1
                                      ? 'checked'
                                      : 'unchecked'
                                  }
                                  color={Colors.RAVEN_BLACK}
                                  uncheckedColor={Colors.RAVEN_BLACK}
                                />
                              ) : null}
                              <Text
                                style={[
                                  styles.paddingL,
                                  Typography.LABEL,
                                  (
                                    multiple
                                      ? value?.indexOf(item.value) !== -1
                                      : item.value === value
                                  )
                                    ? styles.textColorSecondaryColor
                                    : styles.textColorPrimary,
                                ]}>
                                {item.label}
                              </Text>
                            </View>
                          </TouchableRipple>
                        );
                      }}
                      renderSectionHeader={({section}) => (
                        <Text
                          style={[
                            Typography.P2,
                            styles.textColorPrimary,
                            styles.paddingMLeft,
                            styles.backgroundColorWhite,
                          ]}>
                          {section.label}
                        </Text>
                      )}
                      keyExtractor={item => item.value}
                      getItemLayout={(data, index) => ({
                        length: 40,
                        offset: 40 * index,
                        index,
                      })}
                      // stickySectionHeadersEnabled
                      style={[styles.maxHeight200]}
                    />
                  ) : (
                    <FlatList
                      onLayout={initialScroll}
                      ref={flatListRef}
                      data={searchedItems}
                      initialScrollIndex={
                        multiple
                          ? 0
                          : value
                          ? searchedItems.findIndex(
                              item => item.value === value,
                            )
                          : 0
                      }
                      keyExtractor={item => item.value}
                      renderItem={({item}: any) => {
                        return (
                          <TouchableRipple
                            onPress={() => {
                              if (multiple) {
                                if (value?.indexOf(item.value) !== -1) {
                                  onChange(
                                    value.filter(val => val !== item.value),
                                  );
                                } else {
                                  onChange([...value, item.value]);
                                }
                              } else {
                                onChange(item.value);
                                setOpen(false);
                              }
                            }}>
                            <View style={[styles.row]}>
                              {multiple ? (
                                <Checkbox.Android
                                  status={
                                    value?.indexOf(item.value) !== -1
                                      ? 'checked'
                                      : 'unchecked'
                                  }
                                  color={Colors.RAVEN_BLACK}
                                  uncheckedColor={Colors.RAVEN_BLACK}
                                />
                              ) : null}
                              <Text
                                style={[
                                  styles.paddingL,
                                  Typography.LABEL,
                                  (
                                    multiple
                                      ? value?.indexOf(item.value) !== -1
                                      : item.value === value
                                  )
                                    ? styles.textColorSecondaryColor
                                    : styles.textColorPrimary,
                                ]}>
                                {item.label}
                              </Text>
                            </View>
                          </TouchableRipple>
                        );
                      }}
                      getItemLayout={(data, index) => ({
                        length: 40,
                        offset: 40 * index,
                        index,
                      })}
                      style={[styles.maxHeight200]}
                    />
                  )}
                </>
              ) : (
                <View style={[styles.paddingL, styles.alignCenter]}>
                  <Text style={[Typography.P3, styles.textColorPrimary]}>
                    {translations('no_items')}
                  </Text>
                </View>
              )}
            </View>
          </View>
        </Modal>
        {showHelper ? (
          <HelperText
            type={error ? 'error' : 'info'}
            visible={true}
            style={styles.helper}>
            {error ? 'ⓧ ' + (error?.message ? error?.message : error) : helper}
          </HelperText>
        ) : null}
      </>
    );
  },
);

export default SelectInput;
