import React, {useState} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {DataItem} from 'src/design-system';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import SwitchInput from 'src/hook-form/switch-input';
import {Display as RelationshipDisplay} from 'src/form-inputs/relationship-input';

const CaregiverItemDisplay = ({caregiver}: any) => {
  const styles = useStyle();

  return (
    <View>
      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Name</Text>
        <Text style={[Typography.P3, styles.flex13]}>
          {caregiver.getName()}
        </Text>
      </DataItem>
      <RelationshipDisplay value={caregiver.relationship} />
      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Phone #</Text>
        <Text style={[Typography.P3, styles.flex13]}>
          {caregiver.mobilePhone}
        </Text>
      </DataItem>
      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Email</Text>
        <Text style={[Typography.P3, styles.flex13]}>{caregiver.email}</Text>
      </DataItem>
    </View>
  );
};

export const Display = compose(
  withDatabase,
  withState,
  withObservables(
    ['progressReportCaregiver'],
    ({progressReportCaregiver}: any) => ({
      caregiver: progressReportCaregiver.caregiver,
    }),
  ),
)(CaregiverItemDisplay);

const CaregiverItem = ({authCaregiver, caregiver}: any) => {
  const styles = useStyle();
  const [val, setVal] = useState<boolean>(authCaregiver.caregiverInclude);

  return (
    <>
      <View
        style={[
          styles.row,
          styles.flex,
          styles.justifySpaceBetween,
          styles.paddingSM,
        ]}>
        <View style={[styles.flex]}>
          <DataItem>
            <Text style={[Typography.H6]}>Name</Text>
            <Text style={[Typography.P3]}>{caregiver.getName()}</Text>
          </DataItem>
        </View>
        <View style={[styles.flex]}>
          <RelationshipDisplay value={caregiver.relationship} />
        </View>
        <View style={[styles.flex]}>
          <DataItem>
            <Text style={[Typography.H6]}>Phone #</Text>
            <Text style={[Typography.P3]}>{caregiver.mobilePhone}</Text>
          </DataItem>
        </View>
        <View style={[styles.flex]}>
          <DataItem>
            <Text style={[Typography.H6]}>Email</Text>
            <Text style={[Typography.P3]}>{caregiver.email}</Text>
          </DataItem>
        </View>
      </View>
      <SwitchInput
        label={'Include in Report'}
        onChange={async value => {
          await authCaregiver.updateEntity({
            caregiverInclude: value,
          });
          setVal(value);
        }}
        value={val}
        showHelper={false}
      />
    </>
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(['authCaregiver'], ({authCaregiver}: any) => ({
    caregiver: authCaregiver.caregiver,
  })),
)(CaregiverItem);
