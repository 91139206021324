import React, {useEffect, useState} from 'react';
import {ScrollView, Text, View} from 'react-native';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import GoalsDrawer from 'src/modules/patients/components/goal-drawer';
import {useStyle} from 'src/providers/style';
import {Modal} from 'src/design-system';
import {Typography} from 'src/styles';
import ProgressReportProgramGraph from 'src/modules/patients/components/progress-report-program';
import ProgramsInput from 'src/hook-form-inputs/program-multi-select';
import {useSelector} from 'react-redux';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import _ from 'lodash';
import {FormProvider, useForm} from 'react-hook-form';
import {ProgramGraph} from 'src/modules/reports/components';
import {ProgressReportProgram} from 'src/models';

const BehaviorGoalsDisplay = ({authProgram, clientEnvs}: any) => {
  const styles = useStyle();
  const [program, setProgram] = useState<any>(undefined);

  useEffect(() => {
    const fetchProgram = async () => {
      const fetchedProgram = await authProgram.program.fetch();
      setProgram(fetchedProgram);
    };
    fetchProgram();
  }, [authProgram]);

  const {programInfo, programInclude, graphInclude} = authProgram;

  return (
    <View style={[styles.paddingMVertical]}>
      <Text style={[Typography.H6, styles.paddingSMVertical]}>
        {program?.name}
      </Text>
      {programInclude ? (
        <Text style={[Typography.P3]}>{programInfo}</Text>
      ) : (
        <></>
      )}
      {graphInclude && program ? (
        <ProgramGraph
          programId={program?.id}
          envs={clientEnvs}
          startDate={authProgram.startDate}
          endDate={authProgram.endDate}
          showTable={false}
          displayProgramObjective={true}
        />
      ) : (
        <></>
      )}
    </View>
  );
};

const BehaviorGoals = ({progressReport, authPrograms, patient}: any) => {
  const database = useDatabase();
  const styles = useStyle();
  const [selectedTab, setSelectedTab] = useState<string | undefined>(undefined);
  const [addModal, setAddModal] = useState(false);

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  useEffect(() => {
    if (authPrograms?.length > 0 && !selectedTab) {
      setSelectedTab(authPrograms[0].id);
    }

    if (!authPrograms.length && selectedTab) {
      setSelectedTab(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPrograms]);

  const addNewModal = () => {
    setAddModal(true);
  };

  const createDefaultValues = () => {
    const defaultBehaviorPrograms = {};
    authPrograms.forEach(authProgram => {
      defaultBehaviorPrograms[authProgram.id] = authProgram.programInfo || '';
      defaultBehaviorPrograms[authProgram.id + '_include'] =
        authProgram.programInclude;
      defaultBehaviorPrograms[authProgram.id + '_graph_include'] =
        authProgram.graphInclude;
    });
    return defaultBehaviorPrograms;
  };

  const methods = useForm({
    defaultValues: {
      authPrograms: createDefaultValues(),
    },
  });

  const syncPrograms = async (programs: any[], key: string) => {
    const authorizationPrograms = await progressReport[key].fetch();

    const deleteAuthorizationPrograms = _.differenceWith(
      authorizationPrograms,
      programs,
      (authorizationProgram: any, programId: string) => {
        return authorizationProgram.programId === programId;
      },
    );

    const differenceAuthorizationPrograms = _.differenceWith(
      programs,
      authorizationPrograms,
      (programId: string, authorizationProgram: any) => {
        return authorizationProgram.programId === programId;
      },
    );

    for (const authorizationProgram of deleteAuthorizationPrograms) {
      authorizationProgram.delete();
    }

    for (const programId of differenceAuthorizationPrograms) {
      database.write(async () => {
        await database.get(ProgressReportProgram.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = programId;
          entity.progressReport.id = progressReport.id;
          entity.programInclude = true;
          entity.graphInclude = true;
          entity.startDate = progressReport.startDate;
          entity.endDate = progressReport.endDate;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
  };

  useEffect(() => {
    methods.setValue('authPrograms', createDefaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authPrograms]);

  return (
    <View style={[styles.row, styles.paddingTop, styles.width]}>
      <GoalsDrawer
        selectedTab={selectedTab}
        setSelectedTab={setSelectedTab}
        authPrograms={authPrograms}
        addNewGoal={addNewModal}
      />
      <View style={[styles.width]}>
        {selectedTab ? (
          <FormProvider {...methods}>
            <ScrollView
              horizontal={true}
              contentContainerStyle={[styles.width]}>
              <ProgressReportProgramGraph
                programId={selectedTab}
                key={selectedTab}
                patient={patient}
                progressReport={progressReport}
              />
            </ScrollView>
          </FormProvider>
        ) : (
          <View
            style={[styles.card, styles.padding, styles.flex, styles.width80p]}>
            <Text style={[Typography.H4]}>Behavior Goals</Text>
            <Text
              style={[
                Typography.P3,
                styles.paddingBottom,
                styles.textColorGray400,
              ]}>
              Select a program on the left to configure the information that you
              would like to include in the report.
            </Text>
          </View>
        )}
      </View>

      <Modal show={[addModal, setAddModal]} title={'Add Goals'}>
        <Text
          style={[
            Typography.P3,
            styles.paddingBottom,
            styles.textColorGray400,
          ]}>
          Select the programs that you would like to include into the report.
        </Text>
        <ProgramsInput
          label={'Behavior Goals'}
          type={'behavior'}
          patient={progressReport.patientId}
          onChange={value => {
            syncPrograms(value, 'behaviorAuthPrograms');
          }}
          value={authPrograms.map(
            (behaviorAuthProgram: any) => behaviorAuthProgram.programId,
          )}
        />
      </Modal>
    </View>
  );
};

export const Display = BehaviorGoalsDisplay;

export default compose(
  withObservables(['progressReport'], ({progressReport}: any) => ({
    authPrograms: progressReport.behaviorAuthPrograms,
  })),
)(BehaviorGoals);
