import React, {useEffect, useRef, useState} from 'react';
import {Animated, TouchableOpacity, Text, View} from 'react-native';
import Icon from 'react-native-vector-icons/MaterialIcons';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import ProgramListItem from 'src/modules/patients/components/program-list-item';

const GoalsDrawer = ({
  selectedTab,
  setSelectedTab,
  authPrograms = [],
  addNewGoal,
}: any) => {
  const [drawerOpen, setDrawerOpen] = useState(true);
  const drawerWidth = useRef(new Animated.Value(76)).current;
  const styles = useStyle();

  useEffect(() => {
    Animated.timing(drawerWidth, {
      toValue: drawerOpen ? 230 : 90,
      duration: 500,
      useNativeDriver: false,
    }).start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  return (
    <Animated.View
      style={[
        styles.paddingHorizontal,
        styles.paddingTop,
        styles.backgroundColorPrimary50,
        {width: drawerWidth},
      ]}>
      <TouchableOpacity onPress={() => setDrawerOpen(!drawerOpen)}>
        <Icon
          name={
            drawerOpen ? 'subdirectory-arrow-left' : 'subdirectory-arrow-right'
          }
          size={20}
          color={Colors.TEXT_SECONDARY_PROGRAM_CARD}
          style={styles.marginMBottom}
        />
      </TouchableOpacity>
      {authPrograms.map((authProgram: any) => {
        return (
          <TouchableOpacity
            key={authProgram.id}
            style={[
              styles.paddingM,
              styles.marginMVertical,
              styles.row,
              styles.alignCenter,
              styles.borderRadiusSM,
              styles.overflowHidden,
              styles.cursorPointer,
              selectedTab === authProgram.id
                ? styles.backgroundColorSecondary200
                : {},
            ]}
            onPress={() => setSelectedTab(authProgram.id)}>
            <ProgramListItem
              selected={authProgram.programId === selectedTab}
              programId={authProgram.programId}
            />
          </TouchableOpacity>
        );
      })}
      <TouchableOpacity
        style={[
          styles.paddingM,
          styles.marginMVertical,
          styles.row,
          styles.alignCenter,
          styles.borderRadiusSM,
          styles.overflowHidden,
          styles.cursorPointer,
        ]}
        onPress={addNewGoal}>
        <View style={[styles.circleBack]}>
          <Icon
            name={'add-circle-outline'}
            size={33}
            color={Colors.PRIMARY_300}
            style={styles.alignSelfCenter}
          />
        </View>

        {drawerOpen && (
          <Text
            style={[
              Typography.CAPTION_MEDIUM,
              styles.textColorPrimary700,
              styles.marginMLeft,
              //   selectedTab === tab ? {color: Colors.SECONDARY_800} : {},
            ]}
            numberOfLines={1}>
            Add Program
          </Text>
        )}
      </TouchableOpacity>
    </Animated.View>
  );
};

export default GoalsDrawer;
