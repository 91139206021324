/* eslint-disable @typescript-eslint/no-unused-vars */
import React, {useEffect, useState} from 'react';
import {Text, View} from 'react-native';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import {Colors, Typography} from 'src/styles';
import {useStyle} from 'src/providers/style';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import DocumentCard from 'src/modules/patients/components/document-card';
import SwitchInput from 'src/hook-form/switch-input';
import {compose} from 'recompose';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {Q} from '@nozbe/watermelondb';
import {useTranslations} from 'src/providers/translation';
import {useFormContext} from 'react-hook-form';
import DateLayout from 'src/hook-form/date-input';
import SelectInput from 'src/hook-form/select-input';
import moment from 'moment';
import {Separator} from 'src/common-components/atoms';
import {mergeMap, of} from 'rxjs';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {ProgressReportAssessment, User} from 'src/models';
import AsyncStorage from '@react-native-async-storage/async-storage';
import WebView from 'react-native-webview';
import {readRemoteFile} from 'react-papaparse';
import {toDataURL} from 'src/common-utils/toDataURL';
import CsvViewer from 'src/design-system/file-viewer/CsvViewer';
import {ActivityIndicator} from 'react-native-paper';
import {getAgeInYearsMonths} from 'src/common-utils/ageInYearsMonths';

export const times = [
  {label: '12:00 AM', value: '00:00'},
  {label: '12:15 AM', value: '00:15'},
  {label: '12:30 AM', value: '00:30'},
  {label: '12:45 AM', value: '00:45'},
  {label: '1:00 AM', value: '01:00'},
  {label: '1:15 AM', value: '01:15'},
  {label: '1:30 AM', value: '01:30'},
  {label: '1:45 AM', value: '01:45'},
  {label: '2:00 AM', value: '02:00'},
  {label: '2:15 AM', value: '02:15'},
  {label: '2:30 AM', value: '02:30'},
  {label: '2:45 AM', value: '02:45'},
  {label: '3:00 AM', value: '03:00'},
  {label: '3:15 AM', value: '03:15'},
  {label: '3:30 AM', value: '03:30'},
  {label: '3:45 AM', value: '03:45'},
  {label: '4:00 AM', value: '04:00'},
  {label: '4:15 AM', value: '04:15'},
  {label: '4:30 AM', value: '04:30'},
  {label: '4:45 AM', value: '04:45'},
  {label: '5:00 AM', value: '05:00'},
  {label: '5:15 AM', value: '05:15'},
  {label: '5:30 AM', value: '05:30'},
  {label: '5:45 AM', value: '05:45'},
  {label: '6:00 AM', value: '06:00'},
  {label: '6:15 AM', value: '06:15'},
  {label: '6:30 AM', value: '06:30'},
  {label: '6:45 AM', value: '06:45'},
  {label: '7:00 AM', value: '07:00'},
  {label: '7:15 AM', value: '07:15'},
  {label: '7:30 AM', value: '07:30'},
  {label: '7:45 AM', value: '07:45'},
  {label: '8:00 AM', value: '08:00'},
  {label: '8:15 AM', value: '08:15'},
  {label: '8:30 AM', value: '08:30'},
  {label: '8:45 AM', value: '08:45'},
  {label: '9:00 AM', value: '09:00'},
  {label: '9:15 AM', value: '09:15'},
  {label: '9:30 AM', value: '09:30'},
  {label: '9:45 AM', value: '09:45'},
  {label: '10:00 AM', value: '10:00'},
  {label: '10:15 AM', value: '10:15'},
  {label: '10:30 AM', value: '10:30'},
  {label: '10:45 AM', value: '10:45'},
  {label: '11:00 AM', value: '11:00'},
  {label: '11:15 AM', value: '11:15'},
  {label: '11:30 AM', value: '11:30'},
  {label: '11:45 AM', value: '11:45'},
  {label: '12:00 PM', value: '12:00'},
  {label: '12:15 PM', value: '12:15'},
  {label: '12:30 PM', value: '12:30'},
  {label: '12:45 PM', value: '12:45'},
  {label: '1:00 PM', value: '13:00'},
  {label: '1:15 PM', value: '13:15'},
  {label: '1:30 PM', value: '13:30'},
  {label: '1:45 PM', value: '13:45'},
  {label: '2:00 PM', value: '14:00'},
  {label: '2:15 PM', value: '14:15'},
  {label: '2:30 PM', value: '14:30'},
  {label: '2:45 PM', value: '14:45'},
  {label: '3:00 PM', value: '15:00'},
  {label: '3:15 PM', value: '15:15'},
  {label: '3:30 PM', value: '15:30'},
  {label: '3:45 PM', value: '15:45'},
  {label: '4:00 PM', value: '16:00'},
  {label: '4:15 PM', value: '16:15'},
  {label: '4:30 PM', value: '16:30'},
  {label: '4:45 PM', value: '16:45'},
  {label: '5:00 PM', value: '17:00'},
  {label: '5:15 PM', value: '17:15'},
  {label: '5:30 PM', value: '17:30'},
  {label: '5:45 PM', value: '17:45'},
  {label: '6:00 PM', value: '18:00'},
  {label: '6:15 PM', value: '18:15'},
  {label: '6:30 PM', value: '18:30'},
  {label: '6:45 PM', value: '18:45'},
  {label: '7:00 PM', value: '19:00'},
  {label: '7:15 PM', value: '19:15'},
  {label: '7:30 PM', value: '19:30'},
  {label: '7:45 PM', value: '19:45'},
  {label: '8:00 PM', value: '20:00'},
  {label: '8:15 PM', value: '20:15'},
  {label: '8:30 PM', value: '20:30'},
  {label: '8:45 PM', value: '20:45'},
  {label: '9:00 PM', value: '21:00'},
  {label: '9:15 PM', value: '21:15'},
  {label: '9:30 PM', value: '21:30'},
  {label: '9:45 PM', value: '21:45'},
  {label: '10:00 PM', value: '22:00'},
  {label: '10:15 PM', value: '22:15'},
  {label: '10:30 PM', value: '22:30'},
  {label: '10:45 PM', value: '22:45'},
  {label: '11:00 PM', value: '23:00'},
  {label: '11:15 PM', value: '23:15'},
  {label: '11:30 PM', value: '23:30'},
  {label: '11:45 PM', value: '23:45'},
];

const RenderDocument = ({file, accessToken}: any) => {
  const [fileURL, setFileURL] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCsv, setIsCsv] = useState(false);
  const [csvData, setCsvData] = useState<any>([]);
  const styles = useStyle();

  useEffect(() => {
    setIsLoading(true);
    const fetchDataURL = async () => {
      await toDataURL(file.fileLocation, setFileURL);
      setIsLoading(false);
    };
    if (file.fileLocation) {
      if (file.fileLocation.endsWith('.csv')) {
        setIsCsv(true);
        readRemoteFile(file.fileLocation, {
          downloadRequestHeaders: {
            Authorization: `Bearer ${accessToken}`,
          },
          download: true,
          complete: ({data}) => {
            setCsvData(data);
          },
        });
      }
      fetchDataURL();

      return () => {
        setIsCsv(false);
      };
    }
  }, [file?.fileLocation, accessToken]);

  return isLoading ? (
    <ActivityIndicator />
  ) : isCsv ? (
    <CsvViewer csvData={csvData} />
  ) : (
    <WebView source={{uri: fileURL}} style={[styles.windowHeight75]} />
  );
};

const AssessmentDisplay = compose(
  withDatabase,
  withState,
  withObservables(['assessment'], ({assessment, database}: any) => ({
    assessmentDocuments: of([]),
    reportAssessment: database
      .get(ProgressReportAssessment.table)
      .query(
        Q.where('assessment_id', assessment.id),
        Q.where('deleted_at', null),
      )
      .observe()
      .pipe(mergeMap(x => x)),
  })),
)(({assessment, patient, assessmentDocuments, reportAssessment}: any) => {
  const styles = useStyle();
  const database = useDatabase();

  const clientAge = getAgeInYearsMonths(
    patient.birthDate,
    assessment.assessmentDate,
  );

  const [assessor, setAssessor] = useState<any>('');
  const [accessToken, setAccessToken] = useState('');

  const getAccessToken = async () => {
    setAccessToken(await AsyncStorage.getItem('accessToken'));
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  useEffect(() => {
    const fetchAssessor = async () => {
      if (assessment.assessor) {
        const fetchedAssessor = await database
          .get(User.table)
          .find(assessment.assessor);
        setAssessor(
          `${fetchedAssessor?.firstName} ${fetchedAssessor?.lastName}`,
        );
      }
    };
    fetchAssessor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment]);

  return (
    <View style={[styles.flex, styles.paddingMVertical]}>
      <View style={[styles.row, styles.alignCenter, styles.flex]}>
        {reportAssessment?.assessmentDateInclude ? (
          <Text style={[styles.flex, styles.paddingMVertical]}>
            <Text style={[Typography.H6, styles.flex]}>Assessment Date: </Text>
            <Text style={[Typography.P3]}>
              {moment(assessment.assessmentDate).format('L')}
            </Text>
          </Text>
        ) : null}
        {reportAssessment?.assessmentAssessorInclude ? (
          <Text style={[styles.flex, styles.paddingMVertical]}>
            <Text style={[Typography.H6, styles.flex]}>Assessor: </Text>
            <Text style={[Typography.P3]}>{assessor}</Text>
          </Text>
        ) : null}
      </View>
      <View style={[styles.row, styles.alignCenter, styles.flex]}>
        {reportAssessment?.assessmentTimeInclude ? (
          <Text style={[styles.flex, styles.paddingMVertical]}>
            <Text style={[Typography.H6, styles.flex]}>Assessment Time: </Text>
            <Text style={[Typography.P3]}>
              {moment(assessment.startTime, 'HH:mm').format('h:mm A')} -{' '}
              {moment(assessment.endTime, 'HH:mm').format('h:mm A')}
            </Text>
          </Text>
        ) : null}
        {reportAssessment?.assessmentAgeInclude ? (
          <Text style={[styles.flex, styles.paddingMVertical]}>
            <Text style={[Typography.H6, styles.flex]}>Client's Age: </Text>
            <Text style={[Typography.P3]}>{clientAge}</Text>
          </Text>
        ) : null}
      </View>
      <Text style={[styles.flex, styles.paddingMVertical]}>
        <Text style={[Typography.H6, styles.flex]}>Overview: </Text>
        <Text style={[Typography.P3]}>{assessment.overview}</Text>
      </Text>
      {/* Hiding documents until post-MVP */}
      {/* {assessmentDocuments.length && accessToken ? (
        <>
          <Text style={[styles.flex, styles.paddingMVertical]}>
            <Text style={[Typography.H6, styles.flex]}>Documents: </Text>
          </Text>
          {assessmentDocuments.map((document: any) => (
            <RenderDocument file={document} accessToken={accessToken} />
          ))}
        </>
      ) : (
        <></>
      )} */}
    </View>
  );
});

const AssessmentForm = ({
  assessment,
  setShow,
  assessmentDocuments,
  assessors,
  patient,
  name,
  progressReportAssessment,
}: any) => {
  const styles = useStyle();
  const translations = useTranslations();
  const database = useDatabase();
  const {setValue} = useFormContext();
  const assessorsDropdown = assessors.map(assessor => ({
    label: `${assessor.firstName} ${assessor.lastName}`,
    value: assessor.id,
  }));

  const [clientAge, setClientAge] = useState<string>(
    getAgeInYearsMonths(
      patient.birthDate,
      assessment.assessmentDate > 0 ? assessment.assessmentDate : new Date(),
    ),
  );

  const [assessor, setAssessor] = useState('');
  const [assessmentDate, setAssessmentDate] = useState(
    assessment.assessmentDate || new Date(),
  );
  const [startTime, setStartTime] = useState(assessment.startTime || '');
  const [endTime, setEndTime] = useState(assessment.endTime || '');

  const [dateInclude, setDateInclude] = useState<boolean>(
    progressReportAssessment.assessmentDateInclude,
  );
  const [timeInclude, setTimeInclude] = useState<boolean>(
    progressReportAssessment.assessmentTimeInclude,
  );
  const [assessorInclude, setAssessorInclude] = useState<boolean>(
    progressReportAssessment.assessmentAssessorInclude,
  );
  const [ageInclude, setAgeInclude] = useState<boolean>(
    progressReportAssessment.assessmentAgeInclude,
  );

  useEffect(() => {
    setClientAge(
      getAgeInYearsMonths(
        patient.birthDate,
        assessment.assessmentDate > 0 ? assessment.assessmentDate : new Date(),
      ),
    );
    setValue(`${name}.clientAge`, clientAge);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment.assessmentDate]);

  return (
    <>
      <View>
        <View style={[styles.paddingVertical, styles.justifySpaceEvenly]}>
          <View style={[styles.row, styles.alignCenter, styles.flex]}>
            <SwitchInput
              showHelper={false}
              label={''}
              name={`${name}.date_include`}
              onChange={async value => {
                await progressReportAssessment.updateEntity({
                  assessmentDateInclude: value,
                });
                setDateInclude(value);
              }}
              value={dateInclude}
            />
            <Text style={[Typography.H6, styles.flex]}>
              {translations('assessment')} {translations('date')}:
            </Text>
            <View style={[styles.flex2]}>
              <DateLayout
                onChange={async newDate => {
                  await assessment.updateEntity({
                    assessmentDate: newDate,
                  });
                  setAssessmentDate(newDate);
                }}
                value={assessmentDate}
                name={''}
                label={'Date'}
                required={false}
              />
            </View>
          </View>
          <View style={[styles.row, styles.alignCenter, styles.flex]}>
            <SwitchInput
              showHelper={false}
              label={''}
              name={`${name}.time_include`}
              onChange={async value => {
                await progressReportAssessment.updateEntity({
                  assessmentTimeInclude: value,
                });
                setTimeInclude(value);
              }}
              value={timeInclude}
            />
            <Text style={[Typography.H6, styles.flex]}>Assessment Time:</Text>
            <View style={[styles.row, styles.flex2]}>
              <View style={[styles.flex]}>
                <SelectInput
                  items={times}
                  label={'Start Time'}
                  value={assessment.startTime || startTime}
                  onChange={async value => {
                    setStartTime(value);
                    setValue(`${name}.startTime`, value);
                    await assessment.updateEntity({
                      startTime: value,
                    });
                  }}
                />
              </View>
              <Separator width={20} />
              <View style={[styles.flex]}>
                <SelectInput
                  items={times}
                  label={'End Time'}
                  value={assessment.endTime || endTime}
                  onChange={async value => {
                    setEndTime(value);
                    setValue(`${name}.endTime`, value);
                    await assessment.updateEntity({
                      endTime: value,
                    });
                  }}
                />
              </View>
            </View>
          </View>
          <View style={[styles.row, styles.alignCenter, styles.flex]}>
            <SwitchInput
              showHelper={false}
              label={''}
              name={`${name}.assessor_include`}
              onChange={async value => {
                await progressReportAssessment.updateEntity({
                  assessmentAssessorInclude: value,
                });
                setAssessorInclude(value);
              }}
              value={assessorInclude}
            />
            <Text style={[Typography.H6, styles.flex]}>Assessor:</Text>
            <View style={[styles.flex2]}>
              <SelectInput
                items={assessorsDropdown}
                label={'Assessor'}
                value={assessment.assessor || assessor}
                onChange={async value => {
                  setAssessor(value);
                  setValue(`${name}.assessor`, value);
                  await assessment.updateEntity({
                    assessor: value,
                  });
                }}
              />
            </View>
          </View>
          <View style={[styles.row, styles.alignCenter, styles.flex]}>
            <SwitchInput
              showHelper={false}
              label={''}
              name={`${name}.age_include`}
              onChange={async value => {
                await progressReportAssessment.updateEntity({
                  assessmentAgeInclude: value,
                });
                setAgeInclude(value);
              }}
              value={ageInclude}
            />
            <Text style={[Typography.H6, styles.flex]}>
              Client's Age at Assessment:
            </Text>
            <Text style={[styles.flex2]}>{clientAge}</Text>
          </View>
        </View>
        <HookFormInput
          numberOfLines={10}
          name={`${name}.overview`}
          label={'Overview'}
          defaultValue={assessment.overview}
          onBlur={async event => {
            await assessment.updateEntity({
              overview: event.target.value,
            });
          }}
        />
        {/* Hiding documents until post-MVP */}
        {/* {assessmentDocuments.length ? (
          <View style={[styles.row]}>
            {assessmentDocuments.map(doc => {
              return (
                <View key={doc.id} style={[styles.paddingHorizontal]}>
                  <DocumentCard
                    item={doc}
                    canFavorite={false}
                    viewCallback={() => {}}
                    canDelete={true}
                    deleteCallback={async () => {
                      const fetchedDoc = await database
                        .get(AssessmentDocument.table)
                        .query(Q.where('document_id', doc.id))
                        .fetch();
                      if (fetchedDoc.length) {
                        await fetchedDoc[0].delete();
                      }
                    }}
                  />
                </View>
              );
            })}
          </View>
        ) : (
          <></>
        )} */}
        {/* Hiding documents until post-MVP */}
        {/* <View style={[styles.alignSelfStart, styles.paddingVertical]}>
          <RHButton
            onPress={() => {
              setShow(assessment);
            }}
            mode={'outlined'}
            style={[styles.marginBottom]}
            textColor={Colors.RAVEN_BLACK}>
            SELECT/UPLOAD DOCUMENT
          </RHButton>
        </View> */}
        <View style={[styles.separator, styles.marginTop]} />
      </View>
    </>
  );
};

export const Display = AssessmentDisplay;

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['authentication'],
    ({assessment, database, authentication}: any) => ({
      assessors: database
        .get('users')
        .query(
          Q.where('deleted_at', null),
          Q.where('_partition', authentication.selectedGroup),
        ),
      patient: assessment.patient,
      assessmentDocuments: of([]),
      progressReportAssessment: database
        .get(ProgressReportAssessment.table)
        .query(Q.where('assessment_id', assessment.id))
        .observe()
        .pipe(mergeMap(x => x)),
    }),
  ),
)(AssessmentForm);
