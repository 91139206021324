import React, {ReactNode} from 'react';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withObservables from '@nozbe/with-observables';
import {Q} from '@nozbe/watermelondb';
import withState from 'src/redux/wrapper';
import {of} from 'rxjs';
import SelectInput from 'src/hook-form/select-input';

interface Props {
  name?: string;
  label: string;
  items: {
    label: string;
    value: any | string | number;
    custom?: ReactNode;
  }[];
  onChange: (value: any) => void;
  defaultValue?: string;
  value: any[];
  type: string;
  patient: string;
}

const ProgramInput = (props: Props) => {
  return (
    <SelectInput
      label={props.label}
      items={props.items}
      onChange={props.onChange}
      value={props.value}
      selectAll={true}
      multiple={true}
    />
  );
};

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['type', 'patient'],
    ({authentication, database, type, patient}: any) => {
      let filters = [];
      if (type) {
        filters.push(Q.where('type', type));
      }
      if (patient) {
        filters.push(Q.where('patient_id', patient));
      }

      return {
        programs: database
          .get('programs')
          .query(
            Q.where('state', Q.notEq('archived')),
            Q.where('deleted_at', null),
            Q.where('_partition', authentication.selectedGroup),
            Q.sortBy('name', Q.asc),
            ...filters,
          ),
      };
    },
  ),
  withObservables(['programs'], ({programs}: any) => ({
    items: of(
      programs.map((program: any) => ({
        label: program.name,
        value: program.id,
      })),
    ),
  })),
)(ProgramInput);
