import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Typography} from 'src/styles';
import {ReportComponent} from 'src/modules/patients/components';
import ProgramsInput from 'src/hook-form-inputs/program-multi-select';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {useFormContext, useWatch} from 'react-hook-form';
import {DataItem} from 'src/design-system';
import _ from 'lodash';
import {compose} from 'recompose';
import {useSelector} from 'react-redux';
import withObservables from '@nozbe/with-observables';
import {ProgressReportProgram} from 'src/models';

const GoalsDisplay = ({progressReport}: any) => {
  const styles = useStyle();
  const {behaviorInterventionPlan, behaviorInterventionPlanInclude} =
    progressReport;

  return behaviorInterventionPlanInclude ? (
    <>
      <Text style={[Typography.H4, styles.paddingVertical]}>
        ABA Goals and Objectives
      </Text>
      <DataItem>
        <Text style={[Typography.H5, styles.paddingSMVertical]}>
          Behavior Intervention Plan
        </Text>
        <Text style={[Typography.P3]}>{behaviorInterventionPlan}</Text>
      </DataItem>
    </>
  ) : (
    <></>
  );
};

const columnMap = {
  behavior_intervention_plan: 'behaviorInterventionPlan',
  behavior_intervention_plan_include: 'behaviorInterventionPlanInclude',
};

const Goals = ({
  progressReport,
  behaviorAuthPrograms,
  skillAuthPrograms,
}: any) => {
  const styles = useStyle();
  const database = useDatabase();
  const {control} = useFormContext();
  const watchedValues = useWatch({
    control,
    name: 'goals',
  });

  const {selectedGroup, userId} = useSelector(state => state.authentication);

  const syncPrograms = async (programs: any[], key: string) => {
    const authorizationPrograms = await progressReport[key].fetch();

    const deleteAuthorizationPrograms = _.differenceWith(
      authorizationPrograms,
      programs,
      (authorizationProgram: any, programId: string) => {
        return authorizationProgram.programId === programId;
      },
    );

    const differenceAuthorizationPrograms = _.differenceWith(
      programs,
      authorizationPrograms,
      (programId: string, authorizationProgram: any) => {
        return authorizationProgram.programId === programId;
      },
    );

    for (const authorizationProgram of deleteAuthorizationPrograms) {
      authorizationProgram.delete();
    }

    for (const programId of differenceAuthorizationPrograms) {
      database.write(async () => {
        await database.get(ProgressReportProgram.table).create(entity => {
          entity.partition = selectedGroup;
          entity.program.id = programId;
          entity.progressReport.id = progressReport.id;
          entity.programInclude = true;
          entity.graphInclude = true;
          entity.startDate = progressReport.startDate;
          entity.endDate = progressReport.endDate;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
  };

  useEffect(() => {
    const updatedValues = {};
    for (const [formValue, value] of Object.entries(watchedValues)) {
      const columnName = columnMap[formValue];
      if (columnName) {
        if (value !== '') {
          updatedValues[columnName] = value;
        }
      }
    }
    progressReport.updateEntity(updatedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedValues]);

  return (
    <>
      <View>
        <ReportComponent
          title={'Behavior Intervention Plan'}
          label={
            'Select the information that you would like to include into the report.'
          }
          name={'goals.behavior_intervention_plan'}
        />
      </View>

      <View style={[styles.card, styles.padding]}>
        <Text style={[Typography.H4]}>Behavior Goals</Text>
        <Text
          style={[
            Typography.P3,
            styles.paddingBottom,
            styles.textColorGray400,
          ]}>
          Select the information that you would like to include into the report.
        </Text>
        <ProgramsInput
          label={'Behavior Goals'}
          type={'behavior'}
          patient={progressReport.patientId}
          onChange={value => {
            syncPrograms(value, 'behaviorAuthPrograms');
          }}
          value={behaviorAuthPrograms.map(
            (behaviorAuthProgram: any) => behaviorAuthProgram.programId,
          )}
        />
      </View>

      <View style={[styles.card, styles.padding]}>
        <Text style={[Typography.H4]}>Skill Acquisition Goals</Text>
        <Text
          style={[
            Typography.P3,
            styles.paddingBottom,
            styles.textColorGray400,
          ]}>
          Select the information that you would like to include into the report.
        </Text>
        <ProgramsInput
          label={'Skill Goals'}
          type={'skill'}
          patient={progressReport.patientId}
          onChange={value => {
            syncPrograms(value, 'skillAuthPrograms');
          }}
          value={skillAuthPrograms.map(
            (skillAuthProgram: any) => skillAuthProgram.programId,
          )}
        />
      </View>
    </>
  );
};

export const Display = GoalsDisplay;

export default compose(
  withObservables([], ({progressReport}: any) => ({
    behaviorAuthPrograms: progressReport.behaviorAuthPrograms,
    skillAuthPrograms: progressReport.skillAuthPrograms,
  })),
)(Goals);
