import React from 'react';
import {ScrollView} from 'react-native';
import moment from 'moment';
import ClientReport from 'src/modules/reports/components/client-report-beta';
import {FormProvider, useForm, useWatch} from 'react-hook-form';
import {subtractNMonths} from 'src/common-utils/subtractNMonths';

function PatientSummary({patient}: any) {
  const methods = useForm({
    defaultValues: {
      startDate: subtractNMonths(1),
      endDate: new Date(),
      program: '',
    },
  });

  const watchedStartDate = useWatch({
    control: methods.control,
    name: 'startDate',
  });
  const watchedEndDate = useWatch({control: methods.control, name: 'endDate'});
  const watchedProgram = useWatch({control: methods.control, name: 'program'});

  return (
    <FormProvider {...methods}>
      <ScrollView>
        {patient?.id ? (
          <ClientReport
            startDate={moment(watchedStartDate).format()}
            endDate={moment(watchedEndDate).format()}
            client={patient?.id}
            selectedProgramId={watchedProgram}
          />
        ) : (
          <></>
        )}
      </ScrollView>
    </FormProvider>
  );
}

export default PatientSummary;
