import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import {BUGSNAG_API_KEY} from 'react-native-dotenv';

Bugsnag.start({
  apiKey: BUGSNAG_API_KEY,
  plugins: [new BugsnagPluginReact()],
});

import 'react-native-gesture-handler';
import {AppRegistry} from 'react-native';

import App from './src';

import {
  CamptonBlack,
  CamptonBlackItalic,
  CamptonBold,
  CamptonBoldItalic,
  CamptonBook,
  CamptonBookItalic,
  CamptonExtraBold,
  CamptonExtraBoldItalic,
  CamptonExtraLight,
  CamptonExtraLightItalic,
  CamptonLight,
  CamptonLightItalic,
  CamptonMedium,
  CamptonMediumItalic,
  CamptonSemiBold,
  CamptonSemiBoldItalic,
  CamptonThin,
  CamptonThinItalic,
  SegoeUIRegular,
} from 'src/styles/font';
import materialCommunityIcons from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf';
import materialIconFont from 'react-native-vector-icons/Fonts/MaterialIcons.ttf';
import ioniconsFont from 'react-native-vector-icons/Fonts/Ionicons.ttf';
import antDesignFont from 'react-native-vector-icons/Fonts/AntDesign.ttf';
import fontAwesomeFont from 'react-native-vector-icons/Fonts/FontAwesome.ttf';
import featherFont from 'react-native-vector-icons/Fonts/Feather.ttf';
import foundationFont from 'react-native-vector-icons/Fonts/Foundation.ttf';

const fonts = {
  'Campton-Black': CamptonBlack,
  'Campton-BlackItalic': CamptonBlackItalic,
  'Campton-Bold': CamptonBold,
  'Campton-BoldItalic': CamptonBoldItalic,
  'Campton-Book': CamptonBook,
  'Campton-BookItalic': CamptonBookItalic,
  'Campton-ExtraBold': CamptonExtraBold,
  'Campton-ExtraBoldItalic': CamptonExtraBoldItalic,
  'Campton-ExtraLight': CamptonExtraLight,
  'Campton-ExtraLightItalic': CamptonExtraLightItalic,
  'Campton-Light': CamptonLight,
  'Campton-LightItalic': CamptonLightItalic,
  'Campton-Medium': CamptonMedium,
  'Campton-MediumItalic': CamptonMediumItalic,
  'Campton-SemiBold': CamptonSemiBold,
  'Campton-SemiBoldItalic': CamptonSemiBoldItalic,
  'Campton-Thin': CamptonThin,
  'Campton-ThinItalic': CamptonThinItalic,
  'Segoe UI Regular': SegoeUIRegular,
};

const iconFontStyles = `
@font-face {
  src: url(${materialCommunityIcons});
  font-family: MaterialCommunityIcons;
}

@font-face {
  src: url(${materialIconFont});
  font-family: MaterialIcons;
}

@font-face {
  src: url(${ioniconsFont});
  font-family: Ionicons;
}

@font-face {
  src: url(${antDesignFont});
  font-family: AntDesign;
}

@font-face {
  src: url(${fontAwesomeFont});
  font-family: FontAwesome;
}

@font-face {
  src: url(${featherFont});
  font-family: Feather;
}

@font-face {
  src: url(${foundationFont});
  font-family: Foundation;
}

${Object.keys(fonts)
  .map(font => {
    return `
  @font-face {
  src: url(${fonts[font]});
  font-family: ${font};
}
`;
  })
  .join('\n')}
`;

// Create stylesheet
const style = document.createElement('style');
style.type = 'text/css';
if (style.styleSheet) {
  style.styleSheet.cssText = iconFontStyles;
} else {
  style.appendChild(document.createTextNode(iconFontStyles));
}

// Inject stylesheet
document.head.appendChild(style);

AppRegistry.registerComponent('App', () => App);
AppRegistry.runApplication('App', {
  rootTag: document.getElementById('root'),
});
