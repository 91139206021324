import React from 'react';
import {Platform, Keyboard, Text, View, FlatList} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import HookFormInput from 'src/hook-form-wrapper/form-input';
import HookFormSwitchInput from 'src/hook-form-wrapper/switch-input';
import {Controller} from 'react-hook-form';
import TextInput from 'src/hook-form/text-input';
import _ from 'lodash';
import DateLayout from 'src/hook-form/date-input';
import {RHSeparator} from 'src/common-components/custom-ui-helpers';
import {cptCodes} from 'src/common-utils/cptCodes';
import {cptCodes as cptLabels} from 'src/hook-form-inputs/cpt';

export const AuthorizationList = cptCodes.map((code: string) => ({
  cptCode: code.replace('cpt', ''),
  title: cptLabels.find(
    appointmentType =>
      appointmentType.value.indexOf(code.replace('cpt', '')) !== -1,
  )?.label,
  keyName: code,
}));

interface Props {
  title: string;
  label: string;
  name: string;
  control: any;
  progressReport: any;
}

const ReportAuthorizationsComponent = ({
  title,
  label,
  name,
  control,
  progressReport,
}: Props) => {
  const styles = useStyle();
  const [bottom, setBottom] = React.useState(0);

  React.useEffect(() => {
    function onKeyboardChange(e) {
      if (e?.endCoordinates?.screenY <= e?.startCoordinates?.screenY) {
        setBottom(e.endCoordinates.height / 2);
      } else {
        setBottom(0);
      }
    }

    if (Platform.OS === 'ios') {
      const subscription = Keyboard.addListener(
        'keyboardWillChangeFrame',
        onKeyboardChange,
      );
      return () => subscription.remove();
    }

    const subscriptions = [
      Keyboard.addListener('keyboardDidHide', onKeyboardChange),
      Keyboard.addListener('keyboardDidShow', onKeyboardChange),
    ];
    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  const getTotalAuthorizations = () => {
    return cptCodes.reduce((total, cptCode) => {
      return total + progressReport[cptCode];
    }, 0);
  };

  return (
    <View style={[styles.card, styles.padding, {bottom}]}>
      <Text style={[Typography.H4]}>{title}</Text>
      <Text
        style={[Typography.P3, styles.paddingBottom, {color: Colors.GRAY_400}]}>
        {label}
      </Text>
      <HookFormInput label={''} numberOfLines={10} name={name} />
      <View style={styles.row}>
        <Controller
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <DateLayout
              label={'Start Date'}
              onChange={onChange}
              value={value}
              name={''}
              required={false}
              error={error}
            />
          )}
          name={'summary.startDate'}
        />
        <RHSeparator width={40} />
        <Controller
          control={control}
          render={({field: {onChange, value}, fieldState: {error}}) => (
            <DateLayout
              label={'End Date'}
              onChange={onChange}
              value={value}
              name={''}
              required={false}
              error={error}
            />
          )}
          name={'summary.endDate'}
        />
      </View>
      <View style={[styles.column, styles.borderPrimary, styles.border]}>
        {/* eslint-disable-next-line react-native/no-inline-styles */}
        <View style={[styles.row, styles.justifySpaceBetween, {height: 70}]}>
          <View style={[styles.flex, styles.borderPrimary, styles.border]}>
            <View style={styles.marginAuto}>
              <Text style={Typography.P3_BOLD}>Service Code</Text>
            </View>
          </View>
          <View style={[styles.flex, styles.borderPrimary, styles.border]}>
            <View style={styles.marginAuto}>
              <Text style={Typography.P3_BOLD}>Description</Text>
            </View>
          </View>
          <View style={[styles.flex, styles.borderPrimary, styles.border]}>
            <View style={styles.marginAuto}>
              <Text style={Typography.P3_BOLD}>Units Requested</Text>
            </View>
          </View>
        </View>
        <FlatList
          data={AuthorizationList}
          keyExtractor={item => item.keyName}
          renderItem={({item}: any) => {
            return (
              <View
                style={[
                  styles.row,
                  styles.justifySpaceBetween,
                  // eslint-disable-next-line react-native/no-inline-styles
                  {minHeight: 70},
                ]}>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View style={styles.marginAuto}>
                    <Text style={Typography.P3}>{item.cptCode}</Text>
                  </View>
                </View>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View
                    style={[
                      styles.marginAuto,
                      styles.paddingHorizontal,
                      styles.alignCenter,
                    ]}>
                    <Text style={[Typography.P3, styles.textAlignCenter]}>
                      {item.title}
                    </Text>
                  </View>
                </View>
                <View
                  style={[styles.flex, styles.borderPrimary, styles.border]}>
                  <View style={styles.marginAuto}>
                    <Controller
                      control={control}
                      render={({fieldState: {error}}) => (
                        <TextInput
                          onChange={text => {
                            progressReport.updateEntity({
                              [item.keyName]: _.toNumber(text),
                            });
                          }}
                          value={_.toString(progressReport[item.keyName])}
                          label={'Units'}
                          name={''}
                          error={error}
                          keyboardType={'phone-pad'}
                        />
                      )}
                      name={`summary.${item.keyName}`}
                    />
                  </View>
                </View>
              </View>
            );
          }}
        />
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.borderPrimary,
            // eslint-disable-next-line react-native/no-inline-styles
            {height: 70},
          ]}>
          {/* eslint-disable-next-line react-native/no-inline-styles */}
          <View style={[{width: '50%'}, styles.borderPrimary]}>
            <View
              style={[
                styles.marginAutoVertical,
                styles.marginAutoLeft,
                styles.marginRight,
              ]}>
              <Text style={Typography.P3_BOLD}>Total Requested</Text>
            </View>
          </View>
          <View style={[styles.flex, styles.borderPrimary]}>
            <View style={styles.marginAuto}>
              <Text style={Typography.P2}>
                {getTotalAuthorizations()} Units
              </Text>
            </View>
          </View>
        </View>
      </View>
      <View style={styles.paddingTop}>
        <HookFormSwitchInput
          label={'Include in Report'}
          name={`${name}_include`}
        />
      </View>
    </View>
  );
};

export default ReportAuthorizationsComponent;
