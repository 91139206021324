import React, {useState} from 'react';
import {View, Text, TouchableOpacity} from 'react-native';
import {Colors, Typography} from 'src/styles';
import {IconButton} from 'react-native-paper';
import moment from 'moment';
import {useStyle} from 'src/providers/style';
import FilterChip from 'src/design-system/filter-chip';
import {Separator} from 'src/common-components/atoms';

interface Props {
  progressReport: any;
  canEdit: boolean;
  inProgress: boolean;
  editSelected: () => void;
  viewSelected?: () => void;
  canDelete: boolean;
  deleteSelected: (value: any) => void;
}

const ProgressReportListItem = ({
  progressReport,
  canEdit,
  editSelected,
  canDelete,
  deleteSelected,
  inProgress = false,
  viewSelected = () => {},
}: Props) => {
  const styles = useStyle();

  const [hasValidDates] = useState(
    progressReport.startDate > 0 && progressReport.endDate > 0,
  );

  const getDate = (date: Date) => moment(date).format('L');
  const getTime = (date: Date) => moment(date).format('LT');

  return (
    <View style={[styles.card]}>
      <TouchableOpacity
        style={[
          styles.column,
          styles.justifyCenter,
          styles.alignItemsCenter,
          styles.paddingHorizontal,
        ]}
        onPress={() => {
          if (canEdit) {
            inProgress ? editSelected() : viewSelected();
          }
        }}>
        <View
          style={[
            styles.row,
            styles.justifySpaceBetween,
            styles.paddingLVertical,
          ]}>
          <View
            style={[
              styles.paddingLVertical,
              styles.row,
              styles.justifyContentFlexStart,
            ]}>
            <Text style={Typography.P2_MEDIUM}>
              {inProgress
                ? 'Progress Report'
                : !hasValidDates
                ? 'No Date Range Selected'
                : `${getDate(progressReport?.startDate)} - ${getDate(
                    progressReport?.endDate,
                  )}`}
            </Text>
          </View>
          <View
            style={[
              styles.container,
              styles.justifySpaceBetween,
              styles.alignCenter,
            ]}>
            <View style={[styles.container]}>
              {inProgress ? (
                <View style={[styles.container]}>
                  <Text
                    style={[
                      Typography.P3,
                      styles.unitHourText,
                      //   eslint-disable-next-line react-native/no-inline-styles
                      {paddingTop: 4},
                    ]}>
                    Last Edited At: {getDate(progressReport.updatedAt)} @{' '}
                    {getTime(progressReport.updatedAt)}
                  </Text>
                  <Separator width={20} />
                  <FilterChip
                    label={'In Progress'}
                    backgroundColor={Colors.RUBY_300}
                    textColor={Colors.RUBY_700}
                  />
                </View>
              ) : (
                <View style={[styles.container, styles.alignCenter]}>
                  <Text
                    style={[
                      Typography.P3,
                      //   eslint-disable-next-line react-native/no-inline-styles
                      {paddingTop: 4},
                    ]}>
                    Submitted At: {getDate(progressReport.submittedAt)} @{' '}
                    {getTime(progressReport.submittedAt)}
                  </Text>
                  <Separator width={20} />
                  <FilterChip
                    label={'Submitted'}
                    backgroundColor={Colors.SUCCESS}
                    textColor={Colors.SUCCESS_DARK}
                  />
                  <IconButton
                    color={Colors.RAVEN_BLACK}
                    icon={'eye'}
                    onPress={viewSelected}
                  />
                </View>
              )}
            </View>
            <View style={[styles.row]}>
              {canEdit ? (
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon={'pencil'}
                  onPress={editSelected}
                />
              ) : (
                <></>
              )}
              {canDelete ? (
                <IconButton
                  color={Colors.RAVEN_BLACK}
                  icon="trash-can"
                  onPress={deleteSelected}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      </TouchableOpacity>
    </View>
  );
};

export default ProgressReportListItem;
