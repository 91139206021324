import React, {useEffect} from 'react';
import {Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {Display as BirthDateDisplay} from 'src/hook-form-inputs/birth-date';
import {DataItem} from 'src/design-system';
import {getAgeInYearsMonths} from 'src/common-utils/ageInYearsMonths';
import {compose} from 'recompose';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import withState from 'src/redux/wrapper';
import withObservables from '@nozbe/with-observables';
import CaregiversInput from 'src/hook-form-inputs/caregivers';
import CaregiverItem from 'src/modules/patients/components/caregiver-item';
import {Display as CaregiverItemDisplay} from 'src/modules/patients/components/caregiver-item';
import _ from 'lodash';
import {useSelector} from 'react-redux';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {FlatList} from 'react-native-gesture-handler';
import {ProgressReportCaregiver} from 'src/models';
import {useFormContext, useWatch} from 'react-hook-form';

const ClientInfoDisplay = ({
  patient,
  diagnoses,
  progressReportCaregivers,
  activeInsurances,
}: any) => {
  const styles = useStyle();
  const clientName = `${patient.firstName} ${patient.lastName}`;
  const birthDate = patient.birthDate;
  const clientAge = getAgeInYearsMonths(patient.birthDate);
  const memberId = activeInsurances.length ? activeInsurances[0]?.memberId : '';
  const diagnosis = diagnoses.map(diag => diag.diagnosis).join(', ');

  return (
    <View
      style={[
        styles.paddingVertical,
        styles.justifySpaceBetween,
        // eslint-disable-next-line react-native/no-inline-styles
        {maxWidth: 1200, width: '100%'},
      ]}>
      <Text style={[Typography.H5]}>Client Information</Text>
      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Client Name</Text>
        <Text style={[Typography.P3, styles.flex13]}>{clientName}</Text>
      </DataItem>

      <BirthDateDisplay value={birthDate} layout={'row'} />

      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Age</Text>
        <Text style={[Typography.P3, styles.flex13]}>{clientAge}</Text>
      </DataItem>

      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Insurance/Member ID</Text>
        <Text style={[Typography.P3, styles.flex13]}>{memberId}</Text>
      </DataItem>

      <DataItem layout={'row'}>
        <Text style={[Typography.H6, styles.flex03]}>Diagnosis</Text>
        <Text style={[Typography.P3, styles.flex13]}>{diagnosis}</Text>
      </DataItem>

      {progressReportCaregivers?.length ? (
        <>
          <Text style={[Typography.H5, styles.paddingTop]}>
            Caregiver Contact Information
          </Text>
          <FlatList
            data={progressReportCaregivers}
            keyExtractor={item => item.id}
            renderItem={({item, index}: any) =>
              item.caregiverInclude ? (
                <View style={[styles.paddingTop]}>
                  <Text style={[Typography.H5, styles.paddingSMBottom]}>
                    Caregiver #{index + 1}
                  </Text>
                  <CaregiverItemDisplay progressReportCaregiver={item} />
                </View>
              ) : (
                <></>
              )
            }
          />
        </>
      ) : (
        <></>
      )}
    </View>
  );
};

const ClientInfo = ({
  patient,
  diagnoses,
  caregivers,
  progressReport,
  progressReportCaregivers,
  activeInsurances,
}: any) => {
  const styles = useStyle();
  const database = useDatabase();
  const {selectedGroup, userId} = useSelector(state => state.authentication);
  const {control} = useFormContext();

  const clientName = `${patient.firstName} ${patient.lastName}`;
  const birthDate = patient.birthDate;
  const clientAge = getAgeInYearsMonths(patient.birthDate);
  const memberId = activeInsurances.length ? activeInsurances[0]?.memberId : '';
  const diagnosis = diagnoses.map(diag => diag.diagnosis).join(', ');

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const syncCaregivers = async (caregivers: any[]) => {
    const authorizationCaregivers =
      await progressReport.progressReportCaregivers.fetch();

    const deleteAuthorizationCaregivers = _.differenceWith(
      authorizationCaregivers,
      caregivers,
      (authorizationCaregiver: any, caregiverId: string) => {
        return authorizationCaregiver.caregiverId === caregiverId;
      },
    );

    const differenceAuthorizationCaregivers = _.differenceWith(
      caregivers,
      authorizationCaregivers,
      (caregiverId: string, authorizationCaregiver: any) => {
        return authorizationCaregiver.caregiverId === caregiverId;
      },
    );

    for (const authorizationCaregiver of deleteAuthorizationCaregivers) {
      authorizationCaregiver.delete();
    }

    for (const caregiverId of differenceAuthorizationCaregivers) {
      database.write(async () => {
        await database.get(ProgressReportCaregiver.table).create(entity => {
          entity.partition = selectedGroup;
          entity.caregiver.id = caregiverId;
          entity.progressReport.id = progressReport.id;
          entity.caregiverInclude = true;
          entity.createdBy = userId;
          entity.updatedBy = userId;
        });
      });
    }
  };

  const watchedCaregivers = useWatch({
    control,
    name: 'clientInfo.caregiver',
  });

  useEffect(() => {
    syncCaregivers(watchedCaregivers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchedCaregivers]);

  return (
    <>
      <View style={[styles.card, styles.padding]}>
        <Text style={[Typography.H4, styles.paddingSMBottom]}>
          Client Information
        </Text>

        <View
          style={[
            styles.paddingVertical,
            styles.justifySpaceBetween,
            // eslint-disable-next-line react-native/no-inline-styles
            {maxWidth: 1200, width: '100%'},
          ]}>
          <DataItem layout={'row'}>
            <Text style={[Typography.H6, styles.flex03]}>Client Name</Text>
            <Text style={[Typography.P3, styles.flex13]}>{clientName}</Text>
          </DataItem>

          <BirthDateDisplay value={birthDate} layout={'row'} />

          <DataItem layout={'row'}>
            <Text style={[Typography.H6, styles.flex03]}>Age</Text>
            <Text style={[Typography.P3, styles.flex13]}>{clientAge}</Text>
          </DataItem>

          <DataItem layout={'row'}>
            <Text style={[Typography.H6, styles.flex03]}>
              Insurance/Member ID
            </Text>
            <Text style={[Typography.P3, styles.flex13]}>{memberId}</Text>
          </DataItem>

          <DataItem layout={'row'}>
            <Text style={[Typography.H6, styles.flex03]}>Diagnosis</Text>
            <Text style={[Typography.P3, styles.flex13]}>{diagnosis}</Text>
          </DataItem>
        </View>
      </View>
      {caregivers?.length ? (
        <View style={[styles.card, styles.padding]}>
          <Text style={[Typography.H4, styles.paddingSMBottom]}>
            Caregiver Contact Information
          </Text>
          <Text
            style={[
              Typography.P3,
              styles.paddingBottom,
              {color: Colors.GRAY_400},
            ]}>
            Select the information that you would like to include into the
            report.
          </Text>

          <View style={[styles.flex]}>
            <Text style={[Typography.H6, styles.flex]}>Caregiver(s)</Text>
            <View style={[]}>
              <CaregiversInput
                multiple={true}
                selectAll={true}
                patient={patient}
                name={'clientInfo'}
              />
              {progressReportCaregivers.length ? (
                <FlatList
                  data={progressReportCaregivers}
                  keyExtractor={item => item.id}
                  renderItem={({item}: any) => (
                    <CaregiverItem authCaregiver={item} />
                  )}
                />
              ) : (
                <></>
              )}
            </View>
          </View>
        </View>
      ) : (
        <></>
      )}
    </>
  );
};

export const Display = ClientInfoDisplay;

export default compose(
  withDatabase,
  withState,
  withObservables(
    ['patient', 'progressReport'],
    ({patient, progressReport}: any) => ({
      caregivers: patient.activeCaregivers,
      progressReportCaregivers: progressReport.progressReportCaregivers,
      activeInsurances: patient.activeInsurances,
    }),
  ),
)(ClientInfo);
