import {field, relation, text} from '@nozbe/watermelondb/decorators';
import {Base} from 'src/models/base';

export class ProgressReportAssessment extends Base {
  static table = 'progress_report_assessment';
  static associations = {
    assessments: {type: 'belongs_to', key: 'assessment_id'},
    progress_reports: {type: 'belongs_to', key: 'progress_report_id'},
  };

  @relation('assessments', 'assessment_id') assessment;
  @relation('progress_reports', 'progress_report_id') progressReport;

  @text('_partition') partition;
  @field('assessment_date_include') assessmentDateInclude;
  @field('assessment_time_include') assessmentTimeInclude;
  @field('assessment_assessor_include') assessmentAssessorInclude;
  @field('assessment_age_include') assessmentAgeInclude;
}
