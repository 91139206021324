// @ts-nocheck
import {PDFExporterInterface} from 'src/common-components/pdf-export/src';
import Worker from 'src/common-components/pdf-export/src/pdfPageResize.worker';

export class PDFGenerator implements PDFExporterInterface {
  async generatePdf(source: any[], name: string) {
    return new Promise(resolve => {
      const worker = new Worker();

      worker.onmessage = e => {
        const {success, pdfUrl, error} = e.data;

        if (success) {
          const link = document.createElement('a');
          link.href = pdfUrl;
          link.download = `${name}.pdf`;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(pdfUrl);

          resolve(true);
        } else {
          console.error('Failed to generate PDF:', error);
          resolve(false);
        }
      };

      worker.onerror = error => {
        console.error('Worker error:', error);
        resolve(false);
      };

      worker.postMessage(source);
    });
  }
}
