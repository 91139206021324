import React, {useEffect, useState} from 'react';
import {Keyboard, Platform, ScrollView, Text, View} from 'react-native';
import {useStyle} from 'src/providers/style';
import {Colors, Typography} from 'src/styles';
import {compose} from 'recompose';
import withObservables from '@nozbe/with-observables';
import {withDatabase} from '@nozbe/watermelondb/DatabaseProvider';
import {FormSectionHeader, Modal} from 'src/design-system';
import {SYNC_SERVER} from 'react-native-dotenv';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
  Assessment,
  Document,
  ProgressReport,
  ProgressReportAssessment,
} from 'src/models';
import {RHButton} from 'src/common-components/custom-ui-helpers';
import DocumentForm from 'src/modules/patients/components/document-form';
import RadioInput from 'src/hook-form/radio-input';
import SelectInput from 'src/hook-form/select-input';
import {useSelector} from 'react-redux';
import {useDatabase} from '@nozbe/watermelondb/hooks';
import {Q} from '@nozbe/watermelondb';
import AssessmentForm from 'src/modules/patients/components/assessment-form';

const Assessments = ({
  patient,
  activeDocuments,
  assessments,
  progressReportId,
}: any) => {
  const styles = useStyle();
  const database = useDatabase();
  const {selectedGroup} = useSelector(state => state.authentication);
  const [show, setShow] = useState<boolean>(false);
  const [fileOption, setFileOption] = useState<string>('select');
  const [defaultValues, setDefaultValues] = useState({
    fileName: '',
    file: null,
  });

  const [accessToken, setAccessToken] = useState('');
  const [selectedDocument, setSelectedDocument] = useState('');
  const [bottom, setBottom] = React.useState(0);

  const getAccessToken = async () => {
    setAccessToken(await AsyncStorage.getItem('accessToken'));
  };

  useEffect(() => {
    getAccessToken();
  }, []);

  const buttonValues = [
    {value: 'select', title: 'Select Document', label: 'Select'},
    {value: 'upload', title: 'Upload Document', label: 'Upload'},
  ];

  const dropdownAssessments = activeDocuments?.map((document: Document) => ({
    label: document.fileName,
    value: document.id,
  }));

  const addAssessment = async () => {
    return await database.write(async () => {
      const createAssessment = await database
        .get(Assessment.table)
        .create(createdAssessment => {
          createdAssessment.partition = selectedGroup;
          createdAssessment.patient.id = patient.id;
          createdAssessment.assessmentDate = new Date();
        });

      await database.get(ProgressReportAssessment.table).create(entity => {
        entity.partition = selectedGroup;
        entity.progressReport.id = progressReportId;
        entity.assessment.id = createAssessment.id;

        entity.assessmentDateInclude = true;
        entity.assessmentTimeInclude = true;
        entity.assessmentAssessorInclude = true;
        entity.assessmentAgeInclude = true;
      });
    });
  };

  const handleSubmit = async (values: any) => {
    const path = `${selectedGroup}/patients/${patient.id}`;
    const data = new FormData();
    data.append('file', values.file);
    data.append('path', path);
    const response = await fetch(`${SYNC_SERVER}/upload`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      body: data,
    });
    if (!response.ok) {
      throw new Error(await response.text());
    }

    const value = await response.json();

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const newDocument = await database.write(async () => {
      return await database.get(Document.table).create(createdDocument => {
        createdDocument.partition = selectedGroup;
        createdDocument.fileName = values.fileName;
        createdDocument.fileLocation = value.url;
        createdDocument.localFileLocation = '';
        createdDocument.syncStatus = 'synced';
        createdDocument.starred = !!values.intakeType;

        createdDocument.patient.id = patient.id;
      });
    });

    // await database.write(async () => {
    //   await database.get('assessment_document').create(entity => {
    //     entity.assessment.id = show.id;
    //     entity.document.id = newDocument.id;
    //     entity.partition = selectedGroup;
    //   });
    // });

    setShow(false);
    handleCancel();
  };

  const handleCancel = () => {
    setShow(false);
    setDefaultValues({
      fileName: '',
      file: null,
    });
  };

  React.useEffect(() => {
    function onKeyboardChange(e) {
      if (e?.endCoordinates?.screenY <= e?.startCoordinates?.screenY) {
        setBottom(e.endCoordinates.height / 2);
      } else {
        setBottom(0);
      }
    }

    if (Platform.OS === 'ios') {
      const subscription = Keyboard.addListener(
        'keyboardWillChangeFrame',
        onKeyboardChange,
      );
      return () => subscription.remove();
    }

    const subscriptions = [
      Keyboard.addListener('keyboardDidHide', onKeyboardChange),
      Keyboard.addListener('keyboardDidShow', onKeyboardChange),
    ];
    return () => subscriptions.forEach(subscription => subscription.remove());
  }, []);

  return (
    <ScrollView
      style={[styles.card, styles.padding, {bottom}]}
      // eslint-disable-next-line react-native/no-inline-styles
      contentContainerStyle={{marginBottom: 100}}>
      <Text style={[Typography.H4, styles.paddingSMBottom]}>Assessments</Text>
      <Text
        style={[Typography.P3, styles.paddingBottom, {color: Colors.GRAY_400}]}>
        Select the information that you would like to include into the report.
      </Text>
      {assessments.map((assessment, index) => {
        return (
          <View key={assessment.id}>
            <View style={[styles.row, styles.justifySpaceBetween]}>
              <FormSectionHeader title={`Assessment # ${index + 1}`} />
              <View style={[styles.row, styles.alignCenter]}>
                <RHButton
                  icon="minus-circle-outline"
                  mode="outlined"
                  color={Colors.RAVEN_WHITE}
                  textColor={Colors.PRIMARY_300}
                  onPress={async () => {
                    const entity = (
                      await database
                        .get(ProgressReportAssessment.table)
                        .query(Q.where('assessment_id', assessment.id))
                        .fetch()
                    )[0];
                    entity.delete();
                  }}>
                  REMOVE
                </RHButton>
              </View>
            </View>
            <AssessmentForm
              name={`assessments.${index}`}
              assessment={assessment}
              setShow={setShow}
            />
          </View>
        );
      })}
      <View style={[styles.row, styles.justifyStart]}>
        <RHButton
          icon="plus-circle-outline"
          mode="outlined"
          color={Colors.RAVEN_WHITE}
          textColor={Colors.RAVEN_BLACK}
          onPress={async () => {
            await addAssessment();
          }}>
          ADD ASSESSMENT
        </RHButton>
      </View>
      <Modal show={[show, setShow]} title="What would you like to do?">
        <RadioInput
          items={buttonValues}
          onChange={setFileOption}
          value={fileOption}
        />

        {fileOption === 'upload' ? (
          <>
            <View style={[styles.paddingVertical]}>
              <Text style={[Typography.H5]}>Upload Document</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Upload a new document. This document will be stored in the
                client profile.
              </Text>
            </View>
            <DocumentForm
              handleCancel={handleCancel}
              handleSubmit={handleSubmit}
              defaultValues={defaultValues}
              saveText={'Upload'}
            />
          </>
        ) : (
          <>
            <View style={[styles.paddingVertical]}>
              <Text style={[Typography.H5]}>Select Document</Text>
              <Text style={[Typography.P3, styles.textColorSecondary]}>
                Select a document from the client's profile to include here.
              </Text>
            </View>
            <SelectInput
              items={dropdownAssessments}
              label={'Documents'}
              value={selectedDocument}
              onChange={setSelectedDocument}
            />
            <View style={[styles.alignSelfEnd, styles.marginTop]}>
              <RHButton
                onPress={async () => {
                  await database.write(async () => {
                    await database.get('assessment_document').create(entity => {
                      entity.assessment.id = show.id;
                      entity.document.id = selectedDocument;
                      entity.partition = selectedGroup;
                    });
                  });
                  setSelectedDocument('');
                  setShow(false);
                }}
                mode={'contained'}
                style={[styles.marginBottom]}>
                SELECT
              </RHButton>
            </View>
          </>
        )}
      </Modal>
    </ScrollView>
  );
};

export default compose(
  withDatabase,
  withObservables(
    ['patient'],
    ({patient, progressReportId, database}: any) => ({
      progressReport: database
        .get(ProgressReport.table)
        .findAndObserve(progressReportId),
      activeDocuments: patient.activeDocuments,
    }),
  ),
  withObservables(['progressReport'], ({progressReport}: any) => ({
    assessments: progressReport.assessments,
  })),
)(Assessments);
