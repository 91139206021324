import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryLabel,
  VictoryLine,
  VictoryScatter,
  VictoryTheme,
  VictoryVoronoiContainer,
  VictoryTooltip,
  VictoryLegend,
} from 'victory-native';
import {boxShadow} from 'src/styles/mixins';
import {Colors, Typography} from 'src/styles';
import moment from 'moment';
import React, {useState} from 'react';
import {View, Text, Platform} from 'react-native';
import _ from 'lodash';
import {G, ForeignObject} from 'react-native-svg';
import EStyleSheet from 'react-native-extended-stylesheet';
import ViewShot from 'react-native-view-shot';
import {useDimensions} from '@react-native-community/hooks';
import EnvironmentalItemsTable from 'src/modules/patients/components/environmental-items-table';
import {Menu, IconButton as DSIconButton} from 'src/design-system';
import TaskAnalysisSummaryGraph from '../task-analysis-summary-graph';
import TrialByTrialSummaryGraph from '../trial_by_trial_summary_graph';

const isWeb = Platform.OS === 'web';

const stateSymbol = (state: string) => {
  let symbol;
  switch (state) {
    case 'baseline':
      symbol = 'circle';
      break;
    case 'in-treatment':
      symbol = 'diamond';
      break;
    case 'in-maintenance':
      symbol = 'triangleUp';
      break;
    case 'completed':
      symbol = 'square';
      break;
  }
  return symbol;
};

export const stateColor = (state: string) => {
  let color;
  switch (state) {
    case 'baseline':
      color = Colors.SECONDARY_600;
      break;
    case 'in-treatment':
      color = Colors.SECONDARY_800;
      break;
    case 'in-maintenance':
      color = Colors.VIOLET_500;
      break;
    case 'completed':
      color = Colors.LIME_700;
      break;
  }
  return color;
};
const stateText = (state: string) => {
  let text;
  switch (state) {
    case 'baseline':
      text = 'Baseline';
      break;
    case 'in-treatment':
      text = 'In Treatment';
      break;
    case 'in-maintenance':
      text = 'Maintenance';
      break;
    case 'completed':
      text = 'Mastered';
      break;
  }
  return text;
};
const HTMLFlyOut = ({x, y, datum, state, styles, method}: any) => {
  return (
    <G>
      <ForeignObject
        x={x - 70}
        y={y / 2}
        width="140"
        height="600"
        /* eslint-disable-next-line react-native/no-inline-styles */
        style={{overflow: 'visible'}}>
        <View style={styles.card}>
          <Text style={styles.captionMediumTxt}>Date</Text>
          <Text style={styles.captionTxt}>
            {moment(datum.date).format('MM/DD/YY')}
          </Text>

          {datum?.collectors && datum?.collectors.length > 0 && (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Collector
              </Text>
              {datum?.collectors.map((collector: string, index: number) => (
                <Text
                  key={`collector-${collector}-${index}`}
                  style={styles.captionTxt}>
                  {collector}
                </Text>
              ))}
            </>
          )}

          {method === 'task_analysis' || method === 'trial_by_trial' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Percent Correct
              </Text>
              <Text style={styles.captionTxt}>{Math.ceil(datum.y)}%</Text>
            </>
          ) : method === 'duration' && datum.duration !== '' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Total Duration
              </Text>
              <Text style={styles.captionTxt}>{datum.duration}</Text>
            </>
          ) : method === 'rate' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>Rate</Text>
              <Text style={styles.captionTxt}>{datum.y.toFixed(2)}</Text>
            </>
          ) : method === 'frequency' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Total Instances
              </Text>
              <Text style={styles.captionTxt}>{datum.y}</Text>
            </>
          ) : method === 'interval' ? (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Behavior % Responding
              </Text>
              <Text style={styles.captionTxt}>{Math.ceil(datum.y)}%</Text>
            </>
          ) : (
            <></>
          )}

          {datum.target && (
            <>
              <Text style={[styles.captionMediumTxt, styles.mt_10]}>
                Target
              </Text>
              <Text style={styles.captionTxt}>{datum.target}</Text>
            </>
          )}

          <Text style={[styles.captionMediumTxt, styles.mt_10]}>Status</Text>
          <Text style={styles.captionTxt}>{stateText(state)}</Text>
        </View>
      </ForeignObject>
    </G>
  );
};

const Graph = ({
  slot = <></>,
  yAxisLabel = '',
  data = [],
  program = {},
  phaseChanges = [],
  lines = [],
  initialType = 'line',
  maxY = 100,
  title = null,
  extras = {},
  filteredEnvItems = [],
  targets = [],
  sets = [],
  shouldShowSummary = false,
  summaryOnly = false,
  allSets = {},
  analyzeSets = () => {},
  recentSetsByTargetId = {},
}: any) => {
  const [showEnvFactor, setShowEnvFactor] = useState(true);
  const [showEnvChange, setShowEnvChange] = useState(true);
  const [showEnvTable, setShowEnvTable] = useState(false);
  const [showMasteryLine, setShowMasteryLine] = useState(true);
  const [showSummaryReport, setShowSummaryReport] = useState(true);
  const [graph, setGraph] = useState(initialType);
  const styles = getStyles();
  const dimensions = useDimensions();

  const menuOptions = [
    {
      onPress: () => setShowMasteryLine(!showMasteryLine),
      title: 'Mastery Criteria Line',
      icon: 'chart-line-variant',
      color: showMasteryLine ? Colors.LIME_700 : Colors.GRAY_300,
    },
    {
      onPress: () => setGraph('line'),
      title: 'Line Chart',
      icon: 'chart-line',
      color: graph === 'line' ? '' : Colors.GRAY_300,
    },
    {
      onPress: () => setGraph('bar'),
      title: 'Bar Chart',
      icon: 'chart-bar',
      color: graph === 'bar' ? '' : Colors.GRAY_300,
    },
    {
      onPress: () => setShowEnvFactor(!showEnvFactor),
      title: 'Environmental Factors',
      icon: 'chart-line-variant',
      color: showEnvFactor ? Colors.ORANGE_300 : Colors.GRAY_300,
    },
    {
      onPress: () => setShowEnvChange(!showEnvChange),
      title: 'Environmental Changes',
      icon: 'chart-line-variant',
      color: showEnvChange ? Colors.TEAL_600 : Colors.GRAY_300,
    },
    {
      onPress: () => setShowEnvTable(!showEnvTable),
      title: 'Environmental Items Table',
      icon: 'table',
      color: showEnvTable ? '' : Colors.GRAY_300,
    },
  ];

  if (shouldShowSummary) {
    menuOptions.push({
      onPress: () => setShowSummaryReport(!showSummaryReport),
      title: 'Summary Report',
      icon: 'calendar-range',
      color: showSummaryReport ? '' : Colors.GRAY_300,
    });
  }

  if (extras?.setShowProgramObjective) {
    menuOptions.unshift({
      onPress: () =>
        extras?.setShowProgramObjective(!extras?.showProgramObjective),
      title: 'Program Objective',
      icon: 'format-letter-case',
      color: extras?.showProgramObjective ? '' : Colors.GRAY_300,
    });
  }

  const masteryValue =
    program?.method === 'duration'
      ? program?.masteryValue / 60
      : program?.masteryValue;
  let realY = maxY !== 0 ? maxY : 1;
  const domain = {
    x: [0, (data.length || 1) + 1],
    y: [0, showMasteryLine && masteryValue > realY ? masteryValue : realY],
  };

  return (
    <>
      <View style={[styles.flexRow, styles.alignItemCenter, styles.justifyEnd]}>
        <Menu
          anchor={<DSIconButton type={'icon'} icon={'dots-vertical'} />}
          options={menuOptions}
        />
      </View>
      <View style={styles.flexRow}>{slot}</View>
      <ViewShot ref={extras?.graphExportRef || null}>
        {title ? title : null}
        {!summaryOnly ? (
          <VictoryChart
            theme={VictoryTheme.material}
            width={dimensions.screen.width * 0.95}
            domain={domain}
            domainPadding={{x: 40, y: 30}}
            scale={{x: 'time'}}
            containerComponent={<VictoryVoronoiContainer />}>
            <VictoryLegend
              x={10}
              y={0}
              orientation="horizontal"
              gutter={20}
              titleComponent={<></>}
              style={{border: {stroke: 'black', strokeWidth: 0.1}}}
              data={[
                {
                  name: 'Mastery Criteria',
                  symbol: {fill: Colors.RAVEN_BLACK, type: 'minus'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
                {
                  name: 'Environmental Change',
                  symbol: {fill: Colors.TEAL_600, type: 'minus'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
                {
                  name: 'Environmental Factor',
                  symbol: {fill: Colors.ORANGE_300, type: 'minus'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
              ]}
            />
            <VictoryLegend
              x={10}
              y={25}
              orientation="horizontal"
              gutter={20}
              titleComponent={<></>}
              style={{border: {stroke: 'black', strokeWidth: 0.1}}}
              data={[
                {
                  name: 'Baseline',
                  symbol: {fill: Colors.SECONDARY_600, type: 'circle'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
                {
                  name: 'In Treatment',
                  symbol: {fill: Colors.SECONDARY_800, type: 'diamond'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
                {
                  name: 'Maintenance',
                  symbol: {fill: Colors.VIOLET_500, type: 'triangleUp'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
                {
                  name: 'Mastered',
                  symbol: {fill: Colors.LIME_700, type: 'square'},
                  labels: {fontSize: isWeb ? 10 : 9},
                },
              ]}
            />
            {program?.method === 'interval' ||
            program?.method === 'trial_by_trial' ||
            program?.method === 'task_analysis' ? (
              <VictoryAxis
                domain={{y: [0, 100]}}
                domainPadding={{x: [10, -10], y: 5}}
                tickCount={10}
                tickValues={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]}
                dependentAxis
                label={yAxisLabel}
                style={{
                  axis: {stroke: 'transparent'},
                }}
                tickFormat={x => `${x}%`}
                axisLabelComponent={
                  <VictoryLabel
                    style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
                    x={10}
                  />
                }
              />
            ) : program?.method === 'duration' ? (
              <VictoryAxis
                dependentAxis
                label={yAxisLabel}
                style={{
                  axis: {stroke: 'transparent'},
                }}
                axisLabelComponent={
                  <VictoryLabel
                    style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
                    x={10}
                  />
                }
              />
            ) : program?.method === 'frequency' ? (
              <VictoryAxis
                dependentAxis
                label={yAxisLabel}
                tickValues={_.range(1, realY + 2)}
                fixLabelOverlap={true}
                tickFormat={x => {
                  return Math.round(x);
                }}
                style={{
                  tickLabels: {
                    ...Typography.CAPTION,
                    color: Colors.TEXT_PRIMARY,
                  },
                  axis: {stroke: 'transparent'},
                }}
                axisLabelComponent={
                  <VictoryLabel
                    style={{...Typography.CAPTION, color: Colors.TEXT_PRIMARY}}
                    x={15}
                  />
                }
              />
            ) : (
              <VictoryAxis
                dependentAxis
                label={yAxisLabel}
                style={{
                  tickLabels: {
                    ...Typography.CAPTION,
                    color: Colors.TEXT_PRIMARY,
                  },
                  axis: {stroke: 'transparent'},
                }}
                axisLabelComponent={
                  <VictoryLabel
                    style={{
                      ...Typography.CAPTION,
                      color: Colors.TEXT_PRIMARY,
                    }}
                    x={10}
                  />
                }
              />
            )}
            <VictoryAxis
              style={{
                axis: {stroke: Colors.GRAY_100},
                tickLabels: {
                  ...Typography.CAPTION,
                  angle: 45,
                  fill: Colors.PRIMARY_600,
                },
                grid: {stroke: 'transparent'},
              }}
              label={'Session Date'}
              axisLabelComponent={
                <VictoryLabel
                  style={{...Typography.CAPTION, color: Colors.TEXT_PRIMARY}}
                  dy={30}
                />
              }
              tickCount={10}
              tickFormat={x => {
                return moment(data[x - 1]?.startTimestamp).format('MMM DD');
              }}
              tickValues={_.range(1, data.length + 1)}
              tickLabelComponent={
                <VictoryLabel
                  style={{...Typography.CAPTION, fill: Colors.TEXT_PRIMARY}}
                />
              }
            />
            {showMasteryLine ? (
              <VictoryLine
                key={'mastery-line'}
                style={{
                  data: {
                    stroke: Colors.RAVEN_BLACK,
                    strokeWidth: 0.75,
                    strokeDasharray: '4, 2',
                  },
                  labels: {
                    fill: Colors.LIME_700,
                    fontSize: 14,
                  },
                }}
                data={[
                  {x: 0, y: masteryValue},
                  {x: data.length + 1, y: masteryValue},
                ]}
              />
            ) : null}

            {phaseChanges.map((point: any, index: number) => {
              if (point.state) {
                return (
                  <VictoryLine
                    key={`phase-changes-${index}`}
                    style={{
                      data: {
                        stroke: stateColor(point.state),
                        strokeWidth: 0.75,
                      },
                      labels: {
                        fill: stateColor(point.state),
                        fontSize: 14,
                      },
                    }}
                    data={[
                      {x: point.line - 0.5, y: 0},
                      {x: point.line - 0.5, y: domain.y[1]},
                    ]}
                  />
                );
              }
            })}

            {phaseChanges.map((point: any, index: number) => {
              if (point.state) {
                return (
                  <VictoryLabel
                    key={`phase-changes-label-${index}`}
                    text={stateText(point.state)}
                    /* eslint-disable-next-line react-native/no-inline-styles */
                    style={{
                      ...Typography.CAPTION,
                      color: stateColor(point.state),
                      angle: -90,
                    }}
                    datum={{x: point.line - 0.55, y: domain.y[1] / 2}}
                    textAnchor={'middle'}
                  />
                );
              }
            })}

            {graph === 'bar' ? (
              lines.map((line: any, index: number) => {
                if (line.type === 'point') {
                  return (
                    <VictoryBar
                      data={line.points}
                      style={{
                        data: {fill: stateColor(line.state)},
                      }}
                      barWidth={30}
                      labels={() => ' '}
                      labelComponent={
                        <VictoryTooltip
                          key={`point-tooltip-${index}`}
                          renderInPortal={false}
                          flyoutComponent={
                            <HTMLFlyOut
                              state={line.state}
                              styles={styles}
                              method={program?.method}
                            />
                          }
                        />
                      }
                    />
                  );
                } else if (line.type === 'change' && showEnvChange) {
                  return (
                    <VictoryLine
                      key={`env-${index}`}
                      style={{
                        data: {
                          stroke: Colors.TEAL_600,
                          strokeWidth: 0.5,
                          strokeDasharray: '4, 2',
                        },
                        labels: {
                          fill: Colors.RAVEN_BLACK,
                          fontSize: 10,
                        },
                      }}
                      data={[
                        {x: line.x, y: 0},
                        {x: line.x, y: domain.y[1]},
                      ]}
                    />
                  );
                } else if (line.type === 'factor' && showEnvFactor) {
                  return (
                    <VictoryLine
                      key={`env-${index}`}
                      style={{
                        data: {
                          stroke: Colors.ORANGE_300,
                          strokeWidth: 0.5,
                          strokeDasharray: '4, 2',
                        },
                        labels: {
                          fill: Colors.RAVEN_BLACK,
                          fontSize: 10,
                        },
                      }}
                      data={[
                        {x: line.x, y: 0},
                        {x: line.x, y: domain.y[1]},
                      ]}
                    />
                  );
                }
              })
            ) : (
              <></>
            )}

            {graph === 'line' ? (
              lines.map((line: any, index: number) => {
                if (line.type === 'point') {
                  return (
                    <VictoryLine
                      key={`line-${index}`}
                      data={line.points}
                      style={{
                        data: {stroke: stateColor(line.state), strokeWidth: 2},
                      }}
                    />
                  );
                } else if (line.type === 'change' && showEnvChange) {
                  return (
                    <VictoryLine
                      key={`env-${index}`}
                      style={{
                        data: {
                          stroke: Colors.TEAL_600,
                          strokeWidth: 0.5,
                          strokeDasharray: '4, 2',
                        },
                        labels: {
                          fill: Colors.RAVEN_BLACK,
                          fontSize: 10,
                        },
                      }}
                      data={[
                        {x: line.x, y: 0},
                        {x: line.x, y: domain.y[1]},
                      ]}
                    />
                  );
                } else if (line.type === 'factor' && showEnvFactor) {
                  return (
                    <VictoryLine
                      key={`env-${index}`}
                      style={{
                        data: {
                          stroke: Colors.ORANGE_300,
                          strokeWidth: 0.5,
                          strokeDasharray: '4, 2',
                        },
                        labels: {
                          fill: Colors.RAVEN_BLACK,
                          fontSize: 10,
                        },
                      }}
                      data={[
                        {x: line.x, y: 0},
                        {x: line.x, y: domain.y[1]},
                      ]}
                    />
                  );
                } else {
                  return <></>;
                }
              })
            ) : (
              <></>
            )}

            {graph === 'line' ? (
              lines.map((line: any, index: number) => {
                if (line.type === 'point') {
                  return (
                    <VictoryScatter
                      key={`scatter-${index}`}
                      data={line.points}
                      symbol={stateSymbol(line.state)}
                      style={{
                        data: {
                          stroke: stateColor(line.state),
                          strokeWidth: 3,
                          fill: stateColor(line.state),
                        },
                      }}
                      labels={() => ' '}
                      labelComponent={
                        <VictoryTooltip
                          key={`point-tooltip-${index}`}
                          renderInPortal={false}
                          constrainToVisibleArea={true}
                          flyoutComponent={
                            <HTMLFlyOut
                              state={line.state}
                              styles={styles}
                              method={program?.method}
                            />
                          }
                        />
                      }
                    />
                  );
                }
              })
            ) : (
              <></>
            )}
          </VictoryChart>
        ) : (
          <></>
        )}

        {shouldShowSummary && showSummaryReport ? (
          <View style={[styles.paddingVertical]}>
            <Text style={Typography.P2_MEDIUM}>Summary Report</Text>
            {program?.method === 'task_analysis' ? (
              <TaskAnalysisSummaryGraph
                targets={targets}
                sets={sets}
                yAxisLabel={'% Correct'}
              />
            ) : program?.method === 'trial_by_trial' ? (
              <TrialByTrialSummaryGraph
                targets={targets}
                sets={allSets}
                yAxisLabel={'% Correct'}
                analyzeSets={analyzeSets}
                recentSetsByTargetId={recentSetsByTargetId}
              />
            ) : (
              <></>
            )}
          </View>
        ) : null}
        {showEnvTable ? (
          <View style={[styles.paddingVertical, styles.marginTop]}>
            <Text style={Typography.P2_MEDIUM}>
              Environmental Factors and Changes
            </Text>
            <EnvironmentalItemsTable environmentalItems={filteredEnvItems} />
          </View>
        ) : null}
      </ViewShot>
    </>
  );
};

const getStyles = () =>
  EStyleSheet.create({
    pt_20: {
      paddingTop: 20,
    },
    mt_10: {
      marginTop: 10,
    },
    mt_20: {
      marginTop: 20,
    },
    flexRow: {
      flexDirection: 'row',
    },
    padding: {
      padding: 20,
    },
    marginTop: {
      marginTop: 20,
    },
    paddingHorizontal: {paddingHorizontal: 20},
    paddingVertical: {paddingVertical: 20},
    paddingBottom: {paddingBottom: 20},
    paddingLVertical: {paddingVertical: 12},
    alignItemCenter: {
      alignItems: 'center',
    },
    justifySpaceBetween: {
      justifyContent: 'space-between',
    },
    justifyCenter: {justifyContent: 'center'},
    justifyStart: {justifyContent: 'flex-start'},
    justifyEnd: {justifyContent: 'flex-end'},
    alignItemsCenter: {alignItems: 'center'},
    backgroundBlack: {
      backgroundColor: Colors.RAVEN_BLACK,
    },
    backgroundTransparent: {
      backgroundColor: 'transparent',
    },
    paddingMVertical: {
      paddingVertical: 8,
    },
    mr_10: {
      marginRight: 20,
    },
    legendDot: {
      width: 12,
      height: 12,
      borderRadius: 1,
      marginRight: 7,
    },
    legendText: {
      ...Typography.CAPTION_MEDIUM,
      color: '#25262F',
    },
    bgArctic: {
      backgroundColor: Colors.ARCTIC_500,
    },
    bgOrange: {
      backgroundColor: Colors.ORANGE,
    },
    card: {
      borderRadius: 20,
      backgroundColor: Colors.RAVEN_WHITE,
      padding: 15,
      ...boxShadow(Colors.RAVEN_BLACK),
    },
    captionTxt: {
      ...Typography.CAPTION,
      color: '#25262F',
    },
    captionMediumTxt: {
      ...Typography.CAPTION_MEDIUM,
      color: '#25262F',
    },
    alignCenter: {
      alignItems: 'center',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    '@media (max-width: 850px)': {
      column450: {
        flexDirection: 'column',
      },
    },
  });

export default Graph;
